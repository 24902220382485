var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('section',{staticClass:"h-transfer",class:[_vm.tabConfig.length == 1 ? 'single-tab' : '']},[_c('div',{staticClass:"mask"}),_c('div',{staticClass:"transfer__content"},[_c('header',{staticClass:"transfer__header"},[_c('i',{staticClass:"el-icon-monitor"}),_vm._v(" "+_vm._s(_vm.title)+" "),_c('i',{staticClass:"el-icon-close",on:{"click":_vm.closeTransfer}})]),_c('div',{staticClass:"transfer__body"},[_c('div',{staticClass:"transfer-pane"},[_c('div',{staticClass:"transfer-pane__tools"},[_c('el-input',{staticClass:"search-input",staticStyle:{"width":"180px"},attrs:{"size":"mini","type":"search","placeholder":"搜索关键字","disabled":!_vm.searchable},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('span',[_c('span',{staticStyle:{"margin-right":"1rem","font-size":"14px"}},[_vm._v(_vm._s(_vm.selectedNum)+" / "+_vm._s(_vm.maxNum))]),_c('el-tooltip',{attrs:{"placement":"top","content":"清空"}},[_c('i',{staticClass:"el-icon-delete",on:{"click":_vm.removeAll}})])],1)],1),_c('el-scrollbar',{staticClass:"transfer-pane__body shadow right-pane"},[_vm._l((_vm.tabKeys),function(type){return _vm._l((_vm.selectedData[type]),function(item,index){return _c('div',{key:type + index,staticClass:"selected-item",attrs:{"title":_vm.getNodeProp(item, 'label')}},[_c('div',{staticStyle:{"flex":"1"}},[_vm._v(" "+_vm._s(_vm.getNodeProp(item, "label"))+" ")]),_c('div',[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.removeData(item, type)}}}),(_vm.selectType === 'personnel-select' && type !== 'user' &&type !== 'dep&user')?_c('i',{staticClass:"el-icon-remove-outline ml-5",on:{"click":function($event){return _vm.toExclude(item, type)}}}):_vm._e(),(
                    _vm.selectType === 'personnel-select' &&
                    type !== 'user' &&type !== 'dep&user' &&
                    item.ignoreUsers &&
                    item.ignoreUsers.length > 0
                  )?_c('span',{staticClass:"ml-5"},[_vm._v("已忽略"+_vm._s(item.ignoreUsers.length))]):_vm._e()])])})}),_vm._l((_vm.tabKeys),function(type){return _vm._l((_vm.aloneCheckedData[type]),function(item,index){return _c('div',{key:'alone' + type + index,staticClass:"selected-item"},[_c('div',{staticStyle:{"flex":"1"}},[_c('span',[_vm._v(_vm._s(_vm.getNodeProp(item, "label")))])]),_c('div',[_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.removeData(item, type, true)}}}),(_vm.selectType === 'personnel-select' && type !== 'user' &&type !== 'dep&user')?_c('i',{staticClass:"el-icon-remove-outline ml-5",on:{"click":function($event){return _vm.toExclude(item, type)}}}):_vm._e(),(
                  _vm.selectType === 'personnel-select' &&
                  type !== 'user' &&type !== 'dep&user' &&
                  item.ignoreUsers &&
                  item.ignoreUsers.length
                )?_c('span',{staticClass:"ml-5"},[_vm._v("已忽略"+_vm._s(item.ignoreUsers.length))]):_vm._e()])])})})],2),_c('footer',{staticClass:"transfer__footer"},[_c('el-button',{attrs:{"type":_vm.selectedNum * 1 > 0 ? 'primary' : 'info',"size":"mini"},on:{"click":_vm.confirm}},[_vm._v("确定")]),_c('el-button',{attrs:{"plain":"","size":"mini"},on:{"click":_vm.closeTransfer}},[_vm._v("取消")])],1)],1),_c('div',{staticClass:"transfer-pane"},[_c('div',{staticClass:"transfer-pane__body",staticStyle:{"height":"370px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEnough),expression:"isEnough"}],staticClass:"enough-mask"},[_c('span',{staticClass:"p-center"},[_vm._v("最多选择"+_vm._s(_vm.maxNum)+"项")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.searchLoading),expression:"searchLoading"}],staticClass:"searchResPane",class:{ active: _vm.searchMode }},[_c('div',{staticClass:"hidden-tag",on:{"click":function($event){_vm.searchString = ''}}},[_vm._v("关闭")]),_vm._l((_vm.searchRes),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',[_c('div',[_vm._v(_vm._s(_vm.getNodeProp(item, "label")))]),_c('div',{staticClass:"text-ellipsis search-res-tip"},[_vm._v(" "+_vm._s(_vm.getNodeProp(item, "searchResTip"))+" ")])]),_c('el-checkbox',{on:{"change":function (checked) { return checked
                      ? _vm.addData(item)
                      : _vm.removeData(item, _vm.activeTabName, true); }}})],1)})],2),_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('el-tabs',{staticStyle:{"min-height":"370px"},attrs:{"type":"border-card"},model:{value:(_vm.activeTabName),callback:function ($$v) {_vm.activeTabName=$$v},expression:"activeTabName"}},_vm._l((_vm.tabConfig),function(tab_item,idx){return _c('el-tab-pane',{key:idx,attrs:{"name":tab_item.tabKey,"label":tab_item.tabName}},[_c('el-tree',{ref:tab_item.tabKey,refInFor:true,attrs:{"lazy":"","show-checkbox":"","props":{
                    children: tab_item.children,
                    label: tab_item.label,
                    isLeaf: tab_item.isLeaf,
                    disabled: tab_item.disabled,
                  },"load":function (node, resolve) { return _vm.onLoad(node, resolve, tab_item.tabKey); },"node-key":"nodeId","check-strictly":false},on:{"check-change":function (data, checked) { return _vm.onCheckChange(data, checked, tab_item.tabKey); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var node = ref.node;
return _c('span',{staticClass:"ellipsis"},[_c('span',{attrs:{"title":node.label}},[_vm._v(_vm._s(node.label))])])}}],null,true)})],1)}),1)],1)],1)])])]),_c('user-list',{ref:"userList",on:{"doSubmit":_vm.userChange}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }