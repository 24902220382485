<template>
  <div class="app-container">
    <jf-table
      :loading="loading"
      :total="total"
      pageNumField="page"
      pageSizeField="limit"
      :columns="tableColumn"
      :data="dataList"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      @checkbox-change="checkboxChangeEvent"
      @page-change="handlePageChange"
      @handleQuery="handleQuery"
      :stripe="true"
      :filterBtn="false"
      :columnsBtn="false"
      :sortBtn="false"
    >
      <!-- <template slot="query_qarams_item">
          <el-form-item label="院系" prop="dept_name">
            <dept-select
              v-model="queryParams.dept_name"
              :defaultLabel="queryParams.dept_name"
              placeholder="请选择院系"
              valueField="no"
              @change="change"
            />
          </el-form-item>
          <el-form-item label="专业" prop="major_name">
            <major-select
              v-model="queryParams.major_name"
              :defaultLabel="queryParams.major_name"
              placeholder="请选择专业"
              :dept_no="queryParams.dept_name"
              valueField="no"
            />
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <dict-select
              v-model="queryParams.gender"
              placeholder="请选择性别"
              type="stu_gender"
              size="small"
            />
          </el-form-item>
          <el-form-item label="学号" prop="no">
            <el-input
              v-model="queryParams.no"
              placeholder="请输入学号"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入姓名"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="床位号" prop="source">
            <el-input
              v-model="queryParams.source"
              placeholder="请输入床位号"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="房间" prop="source">
            <el-input
              v-model="queryParams.bed_number"
              placeholder="请选择房间"
              size="small"
              style="height: 36px"
              :disabled="true"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="dialogVisible = true"
              >
              </el-button>
            </el-input>
          </el-form-item>
        </template> -->
      <!-- slot="toolbar_btn_left" -->
      <!-- <template slot="toolbar_btn_left">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['/dorm/dormallocbatch/del']"
          >删除</el-button
        >
      </template> -->

      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click.stop="handleView(row)"
          >查看
        </el-button>
      </template>
    </jf-table>

    <!-- 获取房间 -->
    <!-- <el-dialog
        title="获取房间"
        :visible.sync="dialogVisible"
        width="80%"
        height="40%"
        :before-close="handleClose"
      >
        <VueBed @changeBea="onChangeBea"></VueBed>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="verify">确 定</el-button>
        </span>
      </el-dialog> -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="校区" prop="xq_name">
              <el-input
                v-model="form.xq_name"
                placeholder="请输入校区"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="宿舍区" prop="ssq_name">
              <el-input
                v-model="form.ssq_name"
                placeholder="请输入宿舍区"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="宿舍楼" prop="building_name">
              <el-input
                v-model="form.building_name"
                placeholder="请输入宿舍楼"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="楼层" prop="floor_name">
              <el-input
                v-model="form.floor_name"
                placeholder="请输入楼层"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="房间" prop="room_name">
              <el-input
                v-model="form.room_name"
                placeholder="请输入房间"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="床位" prop="bed_name">
              <el-input
                v-model="form.bed_name"
                placeholder="请输入床位"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <!--<el-col :span="12">
					<el-form-item label="部门id" prop="dept_name">
						<el-input
							v-model="form.dept_name"
							placeholder="请输入部门id"
							controls-position="right"
						/>
					</el-form-item> 
				</el-col>-->
          <el-col :span="12">
            <el-form-item label="学号" prop="userno">
              <el-input
                v-model="form.userno"
                placeholder="请输入学号"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓名" prop="username">
              <el-input
                v-model="form.username"
                placeholder="请输入姓名"
                controls-position="right"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="type">
              <dict-select v-model="form.type" type="dorm_bed_change_type" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="请输入备注"
                clearable
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <!-- <el-button type="primary" @click="submitForm">确 定</el-button> -->
          <el-button @click="cancel">取 消</el-button>
        </template>
        <el-button v-else @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
import { getToken } from "@/utils/auth";
import {
  listDormPreallocStudent,
  getDormPreallocStudent,
  addDormPreallocStudent,
  editDormPreallocStudent,
  delDormPreallocStudent,
} from "@/api/dorm/dorm_prealloc_student";

// import VueBed from "./bed.vue";
import { listDormBed } from "@/api/dorm/dorm_bed";
import {
  listDormBedHistory,
  getDormBedHistory,
  addDormBedHistory,
  editDormBedHistory,
  delDormBedHistory,
} from "@/api/dorm/dorm_bed_history";
export default {
  name: "dorm-prealloc-student",
  //   components: {
  //     VueBed,
  //   },
  props: {
    prealloc_id: {
      type: Number,
      default: 0,
    },
    no: {
      type: String,
      default: "202313000088",
    },
    // types: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      dialogVisible: false,
      beaValue: {},

      tableToolbar: {
        // 表格是否包裹刷新,全屏,列设置
        perfect: false,
        // 全屏
        zoom: false,
        // 列设置
        custom: false,
        // 刷新
        // refresh: {
        //   query: this.handleQuery,
        // },
        // 插槽名称
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left", visible: false },
        { type: "seq", width: 60, title: "序号", fixed: "left" },
        { field: "userno", title: "学号", minWidth: 120, fixed: "left" },
        { field: "username", title: "姓名", minWidth: 90, fixed: "left" },
        {
          field: "type",
          title: "业务名称",
          minWidth: 60,
          formatter: (e) => {
            if (e.cellValue == "0") {
              return "退宿";
            } else if (e.cellValue == "1") {
              return "入住";
            }
            return e.cellValue;
          },
        },
        {
          field: "remark",
          title: "原床位号",
          minWidth: 120,
          formatter: (e) => {
            return e.cellValue.substr(11);
          },
        },
        {
          field: "new_bed",
          title: "新床位号",
          minWidth: 120,
          formatter: (e) => {
            return e.row.bed_full_name;
          },
        },
        {
          field: "update_time",
          title: "更新时间",
          minWidth: 120,
          formatter: "formatDate",
        },

        // { field: "operation", title: "类型", minWidth: 120 },

        // { field: "full_name2", title: "床位号2", minWidth: 120 },
        // { field: "userno2", title: "学号2", minWidth: 120 },
        // { field: "username2", title: "姓名2", minWidth: 120 },
        // {
        //   field: "gender2",
        //   title: "性别2",
        //   minWidth: 80,
        //   dictType: "stu_gender",
        //   formatter: this.dictFormat,
        // },
        // { field: "dept_name2", title: "院系2", minWidth: 120 },
        // { field: "major_name2", title: "专业2", minWidth: 120 },
        // { field: "class_name2", title: "班级2", minWidth: 120 },

        {
          field: "",
          title: "操作",
          width: 80,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
          visible: true,
        },
      ],
      loading: true,
      ids: [],
      single: true,
      multiple: true,
      total: 0,
      total2: 0,
      dataList: [],
      dataList2: [],
      female: 0,
      female2: 0,
      title: "",
      readonly: false,
      open: false,
      queryParams: {
        page: 1,
        limit: 10,
        alloc_batch_id: undefined,
        prealloc_id: undefined,
        student_id: undefined,
        no: undefined, // 学号
        name: undefined, // 姓名
        dept_no: undefined,
        dept_name: undefined, // 院系
        major_no: undefined,
        major_name: undefined, // 专业
        class_no: undefined,
        class_name: undefined,
        grade: undefined,
        gender: undefined, // 性别
        ethnicity: undefined,
        source: undefined, // 床位号
        bed_number: undefined, // 房间
        bed_id: undefined, // 房间id
      },
      form: {},
      // formExt: {},
      // extFieldPrefix: "_ext_",
      // upload: {
      //   open: false,
      //   title: "",
      //   isUploading: false,
      //   update: false,
      //   headers: { Authorization: getToken() },
      //   url: process.env.VUE_APP_BASE_API + "/dorm/dormpreallocstudent/import",
      // },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      },
      // 选中值
      records: [],
    };
  },
  created() {
    this.queryParams["userno"] = this.no;

    this.getList();
  },
  methods: {
    getList() {
      this.loading = false;

      let data = {
        userno: this.no,
        page: this.queryParams.page,
        limit: this.queryParams.limit,
      };
      listDormBedHistory(data)
        .then((response) => {
          this.dataList = response.data.list;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 学院数据
    change(a, b, c) {
      this.queryParams.dept_no = c;
    },
    // 选中房间
    onChangeBea(item) {
      this.beaValue = item;
      this.queryParams.bed_id = item.id;
    },
    // 关闭
    handleClose(done) {
      done();
    },

    // 数据
    dictFormat(e) {
      const col = this.tableColumn[e.columnIndex];
      if (col.dictData) {
        const values = e.cellValue ? e.cellValue.split(",") : [];
        const labels = [];
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < col.dictData.values.length; j++) {
            const item = col.dictData.values[j];
            if (v == item.key) {
              labels.push(item.value);
              break;
            }
          }
        }
        return labels.join(",");
      }
      return e.cellValue;
    },

    // 重置
    cancel() {
      this.open = false;
      this.reset();
    },

    // 重置
    reset() {
      this.form = {
        page: undefined,
        limit: undefined,
        no: undefined, // 学号
        name: undefined, // 姓名
        dept_no: undefined,
        dept_name: undefined, // 院系
        major_no: undefined,
        major_name: undefined, // 专业
        class_no: undefined,
        class_name: undefined,
        gender: undefined, // 性别
        source: undefined, // 床位号
        bed_number: undefined, // 房间
        room_id: undefined, // 房间id
      };
      this.resetForm("form");
      this.queryParams = this.form;
      this.queryParams2 = this.form;
    },
    // 搜索按钮
    handleQuery(obj) {
      if (obj && obj.qc_resultData) {
        this.reset();
      }

      this.queryParams.page = 1;
      this.getList();
      this.queryParams2.page = 1;
      this.getNo();
    },

    // resetQuery() {
    //   this.resetForm("queryForm");
    //   this.handleQuery();
    // },

    // 查看
    // handleView(row) {
    //   const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
    //   const loading = this.$loading({
    //     text: "正在加载...",
    //   });
    //   getDormPreallocStudent(id)
    //     .then((response) => {
    //       loading.close();
    //       this.form = response.data;
    //       this.title = "查看";
    //       this.readonly = true;
    //       this.open = true;
    //     })
    //     .catch((e) => {
    //       loading.close();
    //     });
    // },
    checkboxChangeEvent({ records }) {
      // console.log("records", records);
      this.records = records;
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },
    // 已分配选中
    // radioChangeEvent({ row }) {
    //   console.log("选中的数据 =>", row);
    // },
    // // 未分配选中
    // radioChangeEvent({ row }) {
    //   console.log("选中的数据 =>", row);
    // },

    handlePageChange({ currentPage, pageSize }) {
      if (this.queryParams.limit == pageSize) {
        this.getList();
      } else {
        this.queryParams.limit = pageSize;
        this.handleQuery();
      }
    },
    handlePageChange2({ currentPage, pageSize }) {
      if (this.queryParams.limit == pageSize) {
        this.getNo();
      } else {
        this.queryParams2.limit = pageSize;
        this.handleQuery();
      }
    },

    handleDelete(row) {
      if (row.id) {
        this.ids = [row.id];
      }
      this.$confirm("确定删除选中的数据吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.delDormAllocBatch();
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(() => {});
    },

    // 删除选中
    delDormAllocBatch() {
      new Promise((resolve) => {
        this.$emit("delReviewArr", this.ids);
        resolve();
      });
    },

    // 查看
    handleView(row) {
      const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
      const loading = this.$loading({
        text: "正在加载...",
      });
      getDormBedHistory(id)
        .then((response) => {
          loading.close();
          this.form = response.data;
          this.title = "查看";
          this.readonly = true;
          this.open = true;
        })
        .catch((e) => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss">
.el-input-group__append {
  border-left: 0;
  background: #409eff;
  color: #fff;
  height: 36px;
}
.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
// .vxe-table--render-default .vxe-body--row.row--stripe {
//   background-color: #e3f1ff;
// }
.vxe-table--header {
  background-color: #e3f1ff;
}
</style>
