<template>
    <container-item-wrapper :widget="widget">
        <div :class="['sub-form-container', disabledClass, readModeClass]" v-show="!widget.options.hidden">
            <el-row class="header-row">
                <div class="action-header-column">
                    <span class="action-label">{{
                        i18nt("render.hint.subFormAction")
                    }}</span>
                    <el-button v-if="!isReadMode" :disabled="widgetDisabled || actionDisabled || insertDisabled
                        " round type="primary" size="mini" class="action-button" @click="addSubFormRow"
                        :title="i18nt('render.hint.subFormAddActionHint')">
                        {{ i18nt("render.hint.subFormAddAction")
                        }}<i class="el-icon-plus el-icon-right"></i></el-button>
                    <el-button v-if="!isReadMode && isImportBtn" :disabled="widgetDisabled || actionDisabled || insertDisabled
                        " round type="warning" size="mini" class="action-button" @click="importSubFormRow"
                        :title="i18nt('render.hint.subFormImportActionHint')">
                        {{ i18nt("render.hint.subFormImportActionHint")
                        }}<i class="el-icon-download el-icon-right"></i></el-button>
                </div>
            </el-row>
            <div v-for="(subFormRowId, sfrIdx) in rowIdData" class="sub-form-row" :key="subFormRowId">
                <div v-if="leftActionColumn" class="sub-form-action-column hide-label">
                    <div class="action-button-column">
                        <el-button :disabled="widgetDisabled ||
                            actionDisabled ||
                            insertDisabled
                            " circle type="" icon="el-icon-circle-plus-outline" @click="insertSubFormRow(sfrIdx)"
                            v-show="!isReadMode" :title="i18nt('render.hint.insertSubFormRow')"></el-button>
                        <el-button :disabled="widgetDisabled ||
                            actionDisabled ||
                            deleteDisabled
                            " circle type="" icon="el-icon-delete" @click="deleteSubFormRow(sfrIdx)"
                            v-show="!isReadMode" :title="i18nt('render.hint.deleteSubFormRow')"></el-button>
                        <span v-if="widget.options.showRowNumber" class="row-number-span">#{{ sfrIdx + 1 }}</span>
                    </div>
                </div>
                <div v-if="!leftActionColumn && widget.options.showRowNumber" class="row-no-column">
                    <span v-if="widget.options.showRowNumber" class="row-number-span">#{{ sfrIdx + 1 }}</span>
                </div>
                <div class="grid-sub-form-data-row">
                    <template v-for="(subWidget, swIdx) in widget.widgetList">
                        <component :is="getComponentByContainer(subWidget)" :widget="widgetSchemaData[sfrIdx][swIdx]"
                            :key="widgetSchemaData[sfrIdx][swIdx].id" :parent-list="widget.widgetList"
                            :index-of-parent-list="swIdx" :parent-widget="widget" :sub-form-row-id="subFormRowId"
                            :sub-form-row-index="sfrIdx" :sub-form-col-index="swIdx">
                            <!-- 子表单暂不支持插槽！！！ -->
                        </component>
                    </template>
                </div>
                <div v-if="!leftActionColumn" class="sub-form-action-column hide-label">
                    <div class="action-button-column">
                        <!-- <el-button :disabled="widgetDisabled ||
                            actionDisabled ||
                            insertDisabled
                            " circle type="" icon="el-icon-circle-plus-outline" @click="insertSubFormRow(sfrIdx)"
                            v-show="!isReadMode" :title="i18nt('render.hint.insertSubFormRow')"></el-button> -->
                        <el-button :disabled="widgetDisabled ||
                            actionDisabled ||
                            deleteDisabled
                            " circle type="" icon="el-icon-delete" @click="deleteSubFormRow(sfrIdx)"
                            v-show="!isReadMode" :title="i18nt('render.hint.deleteSubFormRow')"></el-button>
                        <el-button :disabled="widgetDisabled ||
                            actionDisabled ||
                            deleteDisabled
                            " circle type="" icon="el-icon-zoom-in" @click="changeData(sfrIdx)" v-show="!isReadMode"
                            title="选择"></el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <el-dialog
            title="导入"
            :visible.sync="show"
            width="50%"
            append-to-body
            :before-close="handleClose"
        >
            <el-upload
                class="import-upload w-100"
                :auto-upload="true"
                :multiple="false"
                drag
                :before-upload="upload"
                action="#"
                :accept="'.xls , .xlsx'"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或<em>点击上传</em>
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog> -->
        <el-dialog :title="widget.options.form_type == 1 ? '表单数据' : '流程数据'" :visible.sync="show" width="80%"
            :before-close="handleClose" :append-to-body="true">
            <div v-if="widget.options.form_type == 1">
                <form-table ref="formTable" :config="widget.options" @change="checkboxChangeEvent"></form-table>
            </div>
            <div v-if="widget.options.form_type == 2">
                <flow-table ref="flowTable" :config="widget.options" @change="checkboxChangeEvent"></flow-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button type="primary" :disabled="checkedList.length !== 1" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </container-item-wrapper>
</template>

<script>
import emitter from "@/vform/utils/emitter";
import i18n from "../../../utils/i18n";
import {
    deepClone,
    generateId,
    traverseFieldWidgetsOfContainer,
} from "../../../utils/util";
import refMixin from "../../../components/form-render/refMixin";
import ContainerItemWrapper from "./container-item-wrapper";
import containerItemMixin from "./containerItemMixin";
import FieldComponents from "@/vform/components/form-designer/form-widget/field-widget/index";
import { read, utils } from "xlsx";
import FlowTable from "@/components/jfFlowTable/flowTable";
import FormTable from "@/components/jfFormTable/formTable";
export default {
    name: "vf-form-item",
    componentName: "ContainerItem",
    mixins: [emitter, i18n, refMixin, containerItemMixin],
    components: {
        ContainerItemWrapper,
        ...FieldComponents,
        FlowTable,
        FormTable
    },
    props: {
        widget: Object,
    },
    widget: {
        handler(val) {
            console.log(val, '测试升级');
        },
        deep: true,
        immediate: true,
    },
    provide() {
        return {
            getSubFormFieldFlag: () => true,
            getSubFormName: () => this.widget.options.name,
        };
    },
    inject: ["refList", "sfRefList", "globalModel", "getReadMode"],
    data() {
        return {
            rowIdData: [],
            widgetSchemaData: [],
            actionDisabled: false,
            insertDisabled: false, //是否禁止新增、插入记录
            deleteDisabled: false, //是否禁止删除记录

            fieldWidgetList: [],
            show: false, //打开弹窗
            worksheet: [],
            checkedList: [],
            show: false,
            rowIndex:0
        };
    },
    computed: {
        isReadMode() {
            return this.getReadMode();
        },
       
        isImportBtn() {
            return this.widget.options.importBtn;
        },

        leftActionColumn() {
            return (
                (this.widget.options.actionColumnPosition || "left") === "left"
            );
        },

        widgetDisabled() {
            return !!this.widget.options.disabled;
        },

        disabledClass() {
            return !!this.widget.options.disabled ? "sub-form-disabled" : "";
        },

        readModeClass() {
            return !!this.getReadMode() ? "sub-form-read-mode" : "";
        },
    },
    created() {
        this.initRefList();
        this.registerSubFormToRefList();
        this.initRowIdData(true);
        this.initWidgetSchemaData();
        this.initEventHandler();
    },
    mounted() {
        this.extractFieldWidgetList();
        this.handleSubFormFirstRowAdd(); //默认添加首行后，主动触发相关事件！！
    },
    beforeDestroy() {
        this.unregisterFromRefList();
    },
    methods: {
        changeData(index) {
            this.show = true;
            this. rowIndex=index
            console.log(index);
            let mid = this.widget.options.form_data.id;
            if (this.widget.options.form_type == 1) {
                this.$nextTick(() => {
                    this.$refs["formTable"].init(mid);
                });
            } else if (this.widget.options.form_type == 2) {
                this.$nextTick(() => {
                    this.$refs["flowTable"].init(mid);
                });
            }
        },
        checkboxChangeEvent(e) {
            this.checkedList = e;
        },
        // 确认按钮赋值
        onSubmit() {
            let val = this.checkedList[0];
            let obj = {}
            // this.widget.cols.forEach(item => {
            //     item.widgetList.forEach(widget => {
            //         widget.options.defaultValue = val[widget.options.name]
            //         obj[widget.options.name] = val[widget.options.name]
            //         this.$set(this.globalModel.formModel, widget.options.name, val[widget.options.name])
            //     })

            // })
            // this.setRefreshData(obj)
            // this.widget.cols = JSON.parse(JSON.stringify(this.widget.cols))
            //   this.globalModel.formModel = Object.assign(this.globalModel.formModel,obj)

            //   let widgetList = this.widget.widgetList;
              let subFormValues = JSON.parse(
                JSON.stringify(this.globalModel.formModel[this.widget.options.name])
              );
            //   widgetList.forEach((item) => {
            //     obj[item.options.name] = val[item.options.name];
            //   });
            for(let i in subFormValues[this.rowIndex]){
                obj[i] = val[i]
            }
              console.log(subFormValues,val)
              subFormValues[this.rowIndex] = obj;
              this.setSubFormValues(subFormValues);
            this.handleClose();
        },
        extractFieldWidgetList() {
            this.fieldWidgetList.splice(0, this.fieldWidgetList.length);
            let fieldListFn = (fw) => {
                this.fieldWidgetList.push(fw);
            };
            traverseFieldWidgetsOfContainer(this.widget, fieldListFn);
        },

        getLabelAlign(widget, subWidget) {
            return subWidget.options.labelAlign || widget.options.labelAlign;
        },

        registerSubFormToRefList() {
            if (this.widget.type === "grid-sub-form" || this.widget.type === "form") {
                this.sfRefList[this.widget.options.name] = this;
            }
        },

        initRowIdData(initFlag) {
            console.log(this.rowIdData)
            if (this.widget.type === "grid-sub-form" || this.widget.type === "form") {

                this.rowIdData.splice(0, this.rowIdData.length); //清除数组必须用splice，length=0不会响应式更新！！
                let subFormModel = this.formModel[this.widget.options.name];
                if (typeof subFormModel == "string") {
                    subFormModel = JSON.parse(subFormModel);
                }
                if (!!subFormModel && subFormModel.length > 0) {

                    subFormModel.forEach(() => {
                        this.rowIdData.push("id" + generateId());
                    });
                    console.log('进入这里了吗subFormModel', this.rowIdData)
                    if (!!initFlag) {
                        //注意：事件触发需延期执行，SumFormDataChange事件处理代码中可能存在尚未创建完成的组件！！
                        setTimeout(() => {
                            this.handleSubFormRowChange(subFormModel);
                        }, 800);
                    }
                }
            }
        },

        addToRowIdData() {
            this.rowIdData.push("id" + generateId());
        },

        insertToRowIdData(rowIndex) {
            this.rowIdData.splice(rowIndex, 0, "id" + generateId());
        },

        deleteFromRowIdData(rowIndex) {
            this.rowIdData.splice(rowIndex, 1);
        },

        getRowIdData() {
            return this.rowIdData;
        },

        getWidgetRefOfSubForm(widgetName, rowIndex) {
            let realWidgetName = widgetName + "@row" + this.rowIdData[rowIndex];
            return this.getWidgetRef(realWidgetName);
        },

        initWidgetSchemaData() {
            //初始化widgetSchemaData！！！
            if (this.widget.type !== "grid-sub-form" && this.widget.type !== "form") {
                return;
            }

            let rowLength = this.rowIdData.length;
            this.widgetSchemaData.splice(0, this.widgetSchemaData.length); //清除数组必须用splice，length=0不会响应式更新！！
            if (rowLength > 0) {
                for (let i = 0; i < rowLength; i++) {
                    let widgetSchemas = [];
                    this.widget.widgetList.forEach((swItem) => {
                        widgetSchemas.push(this.cloneSchemaOfWidget(swItem));
                    });
                    this.widgetSchemaData.push(widgetSchemas);
                }
            }
        },

        addToWidgetSchemaData(rowIndex) {
            let widgetSchemas = [];
            this.widget.widgetList.forEach((swItem) => {
                widgetSchemas.push(this.cloneSchemaOfWidget(swItem));
            });

            if (rowIndex === undefined) {
                this.widgetSchemaData.push(widgetSchemas);
            } else {
                this.widgetSchemaData.splice(rowIndex, 0, widgetSchemas);
            }
        },

        deleteFromWidgetSchemaData(rowIndex) {
            this.widgetSchemaData.splice(rowIndex, 1);
        },

        cloneSchemaOfWidget(widget) {
            let newWidgetSchema = deepClone(widget);
            newWidgetSchema.id = widget.type + generateId();
            return newWidgetSchema;
        },

        initEventHandler() {
            if (this.widget.type !== "grid-sub-form" && this.widget.type !== "form") {
                return;
            }

            this.$on("setFormData", (newFormData) => {
                this.initRowIdData(false);
                this.initWidgetSchemaData();

                let subFormData = newFormData[this.widget.options.name] || [];
                setTimeout(() => {
                    //延时触发SubFormRowChange事件, 便于更新计算字段！！
                    this.handleSubFormRowChange(subFormData);
                }, 800);
            });
        },

        handleSubFormFirstRowAdd() {
            if (this.widget.type !== "grid-sub-form" && this.widget.type !== "form") {
                return;
            }

            if (
                !!this.widget.options.showBlankRow &&
                this.rowIdData.length === 1
            ) {
                let oldSubFormData =
                    this.formModel[this.widget.options.name] || [];

                //确认组件创建成功后触发事件!!
                this.$nextTick(() => {
                    this.handleSubFormRowAdd(oldSubFormData, this.rowIdData[0]);
                    this.handleSubFormRowChange(oldSubFormData);

                    if (!!this.widget.options.disabled) {
                        this.disableSubForm();
                    }
                });
            }
        },

        addSubFormRow() {
            let newSubFormDataRow = {};
            this.fieldWidgetList.forEach((subFormItem) => {
                if (!!subFormItem.formItemFlag) {
                    newSubFormDataRow[subFormItem.options.name] =
                        subFormItem.options.defaultValue;
                }
            });

            let oldSubFormData = this.formModel[this.widget.options.name] || [];
            if (typeof oldSubFormData == "string") {
                oldSubFormData = JSON.parse(oldSubFormData);
            }

            oldSubFormData.push(newSubFormDataRow);
            this.addToRowIdData();
            this.addToWidgetSchemaData();

            //确认组件创建成功后触发事件!!
            this.$nextTick(() => {
                this.handleSubFormRowAdd(
                    oldSubFormData,
                    this.rowIdData[oldSubFormData.length - 1]
                );
                this.handleSubFormRowChange(oldSubFormData);
            });
        },
        importSubFormRow() {
            console.log("导入");
            this.show = true;
        },
        handleClose() {
            this.show = false;
        },
        //上传
        upload(file, fileList) {
            let files = [file];
            let imgSize = Number(file.size / 1024 / 1024);
            if (files.length <= 0) {
                this.$msgbox({
                    title: "",
                    message: "上传失败请重新上传",
                    type: "warning",
                });
                return false;
            }
            if (imgSize > 10) {
                this.$msgbox({
                    title: "",
                    message: "文件大小不能超过10MB，请重新上传。",
                    type: "warning",
                });
                return false;
            }
            if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                this.$Message.error("上传格式不正确，请上传xls或者xlsx格式");
                return;
            }
            // this.readExcel1(files);
            this.parseExcel(files[0]);

            return false; //false表示不需要上传~
        },
        parseExcel(file) {
            console.log(file);
            var that = this;
            // this.divLoading = true;
            // this.loading_text = "上传中~";

            // this.filename = file.name;
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = read(data, { type: "binary" });
                    this.worksheet = [];
                    console.log(workbook, "workbook");
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];

                    // 生成json表格内容
                    workbook.SheetNames.forEach((wsname) => {
                        let ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                        console.log(ws, "ws");
                        const jsonData = utils.sheet_to_json(
                            workbook.Sheets[wsname],
                            {
                                header: 1,
                            }
                        );
                        // 获取表头，通常是第一行
                        const headerRow = jsonData[0] || [];

                        let strings = workbook.Sheets[wsname];
                        let array = [];
                        for (let i = 0; i < headerRow.length; i++) {
                            array.push({
                                field: headerRow[i],
                                title: headerRow[i],
                                minWidth: 100,
                            });
                        }

                        let obj = {
                            wsname: wsname,
                            ws: ws,
                            columns: array,
                        };

                        this.worksheet.push(obj);
                    });
                    // // 后续为自己对ws数据的处理
                    // this.form.region = 0;

                    this.getImportList(
                        this.worksheet[0].ws,
                        1,
                        this.worksheet[0].columns
                    );
                } catch (e) {
                    return false;
                }

                // 将表头和数据存储在组件的数据属性中
                // this.excelHeader = headerRow;
                // this.excelData = dataRows;
            };
            console.log(this.worksheet, "this.worksheet");
            reader.readAsBinaryString(file);
        },
        getImportList(list, type, columns) {
            console.log(
                list,
                type,
                columns,
                this.widget,
                "list, type, columns"
            );
            let widgetList = deepClone(this.widget.widgetList);
            let fields = [];
            widgetList[0].cols.forEach((item) => {
                item.widgetList.forEach((o) => {
                    fields.push(o);
                });
            });
            let array = [];
            list.forEach((item) => {
                let obj = {};
                console.log(item, "item");
                for (let i in item) {
                    let fieldsIndex = fields.findIndex(
                        (o) => o.options.label == i
                    );

                    if (fieldsIndex > -1) {
                        obj[fields[fieldsIndex].options.name] = item[i];
                    }
                }
                array.push(obj);
            });
            // this.widgetSchemaData=widgetSchemaData
            this.show = false;
            this.setSubFormValues(array);
        },
        insertSubFormRow(beforeFormRowIndex) {
            let newSubFormDataRow = {};
            this.fieldWidgetList.forEach((subFormItem) => {
                if (!!subFormItem.formItemFlag) {
                    newSubFormDataRow[subFormItem.options.name] =
                        subFormItem.options.defaultValue;
                }
            });

            let oldSubFormData = this.formModel[this.widget.options.name] || [];
            oldSubFormData.splice(beforeFormRowIndex, 0, newSubFormDataRow);
            this.insertToRowIdData(beforeFormRowIndex);
            this.addToWidgetSchemaData(beforeFormRowIndex);

            //确认组件创建成功后触发事件!!
            this.$nextTick(() => {
                this.handleSubFormRowInsert(
                    oldSubFormData,
                    this.rowIdData[beforeFormRowIndex]
                );
                this.handleSubFormRowChange(oldSubFormData);
            });
        },

        deleteSubFormRow(formRowIndex) {
            this.$confirm(
                this.i18nt("render.hint.deleteSubFormRow") + "?",
                this.i18nt("render.hint.prompt"),
                {
                    confirmButtonText: this.i18nt("render.hint.confirm"),
                    cancelButtonText: this.i18nt("render.hint.cancel"),
                }
            )
                .then(() => {
                    let oldSubFormData =
                        this.formModel[this.widget.options.name] || [];
                    let deletedDataRow = deepClone(
                        oldSubFormData[formRowIndex]
                    );
                    oldSubFormData.splice(formRowIndex, 1);
                    this.deleteFromRowIdData(formRowIndex);
                    this.deleteFromWidgetSchemaData(formRowIndex);

                    //确认组件创建成功后触发事件!!
                    this.$nextTick(() => {
                        this.handleSubFormRowDelete(
                            oldSubFormData,
                            deletedDataRow
                        );
                        this.handleSubFormRowChange(oldSubFormData);
                    });
                })
                .catch(() => {
                    //
                });
        },

        handleSubFormRowChange(subFormData) {
            if (!!this.widget.options.onSubFormRowChange) {
                let customFunc = new Function(
                    "subFormData",
                    this.widget.options.onSubFormRowChange
                );
                customFunc.call(this, subFormData);
            }
        },

        handleSubFormRowAdd(subFormData, newRowId) {
            if (!!this.widget.options.onSubFormRowAdd) {
                let customFunc = new Function(
                    "subFormData",
                    "newRowId",
                    this.widget.options.onSubFormRowAdd
                );
                customFunc.call(this, subFormData, newRowId);
            }
        },

        handleSubFormRowInsert(subFormData, newRowId) {
            if (!!this.widget.options.onSubFormRowInsert) {
                let customFunc = new Function(
                    "subFormData",
                    "newRowId",
                    this.widget.options.onSubFormRowInsert
                );
                customFunc.call(this, subFormData, newRowId);
            }
        },

        handleSubFormRowDelete(subFormData, deletedDataRow) {
            if (!!this.widget.options.onSubFormRowDelete) {
                let customFunc = new Function(
                    "subFormData",
                    "deletedDataRow",
                    this.widget.options.onSubFormRowDelete
                );
                customFunc.call(this, subFormData, deletedDataRow);
            }
        },

        setDisabled(flag) {
            this.widget.options.disabled = flag;
            if (!!flag) {
                this.disableGridSubForm();
            } else {
                this.enableGridSubForm();
            }
        },

        /**
         * 设置单行子表单是否禁止新增、插入记录
         * @param flag
         */
        setInsertDisabled(flag) {
            this.insertDisabled = flag;
        },

        /**
         * 设置单行子表单是否禁止删除记录
         * @param flag
         */
        setDeleteDisabled(flag) {
            this.deleteDisabled = flag;
        },

        /**
         * 单独给子表单赋值
         * 注意：该方法仅触发组件的onChange事件以及子表单的onFormRowChange事件，不会触发表单的onFormDataChange等其他事件！！
         * @param subFormValues
         */
        setSubFormValues(subFormValues) {
            this.$set(
                this.globalModel.formModel,
                this.widget.options.name,
                subFormValues
            );
            this.initRowIdData(false);
            this.initWidgetSchemaData();

            setTimeout(() => {
                //延时触发SubFormRowChange事件, 便于更新计算字段！！
                this.handleSubFormRowChange(subFormValues);
            }, 800);
        },

        /**
         * 单独为子表单某行的字段组件赋值
         * @param fieldName
         * @param fieldValue
         * @param rowIndex
         */
        setSubFormFieldValue(fieldName, fieldValue, rowIndex) {
            const subFormData =
                this.globalModel.formModel[this.widget.options.name];
            subFormData[rowIndex][fieldName] = fieldValue;

            this.handleSubFormRowChange(subFormData);
        },
    },
};
</script>

<style lang="scss" scoped>
.sub-form-container {
    margin-bottom: 8px;
    text-align: left; //IE浏览器强制居左对齐

    ::v-deep .el-row.header-row {
        padding: 0;
        display: flex;
    }

    ::v-deep div.sub-form-row {
        padding: 0;
        display: flex;
        align-items: center;
        border: 1px solid #e1e2e3;

        .row-number-span {
            margin-left: 16px;
        }
    }
}

div.action-header-column {
    display: inline-block;
    //width: 120px;
    width: 100%;
    border: 1px solid #e1e2e3;
    background: #f1f2f3;
    padding: 8px;

    .action-label {
        margin-right: 12px;
    }

    .action-button {
        padding-left: 8px;
        padding-right: 8px;
    }
}

div.field-header-column {
    display: inline-block;
    //overflow: hidden;
    //white-space: nowrap;  //文字超出长度不自动换行
    //text-overflow: ellipsis;  //文字超出长度显示省略号
    border: 1px solid #e1e2e3;
    background: #f1f2f3;
    padding: 8px;

    span.custom-label i {
        margin: 0 3px;
    }
}

div.field-header-column.is-required:before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
}

div.label-center-left {
    text-align: left;
}

div.label-center-align {
    text-align: center;
}

div.label-right-align {
    text-align: right;
}

div.sub-form-action-column {
    display: inline-block;
    align-items: center;
    text-align: center;
    width: 120px;
    padding: 8px;

    ::v-deep .el-form-item {
        margin-bottom: 0;
    }

    ::v-deep .el-button {
        font-size: 18px;
        padding: 0;
        background: #dcdfe6;
        border: 4px solid #dcdfe6;
    }
}

div.grid-sub-form-data-row {
    display: inline-block;
    width: 100%;
    border-left: 1px solid #e1e2e3;
    border-right: 1px solid #e1e2e3;
}

div.sub-form-action-column.hide-label {
    ::v-deep .el-form-item__label {
        display: none;
    }
}

div.row-no-column {
    display: flex;
    align-items: center;
    width: 50px;
    border-radius: 10px;
    background: #f1f2f3;
    padding: 5px 0;
    margin: 0 6px;
}

div.sub-form-table-column {
    display: inline-block;
    border: 1px solid #e1e2e3;
    padding: 8px;

    ::v-deep .el-form-item {
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 0;
    }

    ::v-deep .el-form-item__content {
        margin-left: 0 !important;
    }
}

div.sub-form-table-column.hide-label {
    ::v-deep .el-form-item__label {
        display: none;
    }
}
</style>
