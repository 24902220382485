import request from '@/utils/request'

export function listDormPreallocStudent(query) {
  return request({
    url: '/dorm/dormpreallocstudent/list',
    method: 'get',
    params: query
  })
}

export function statDormPreallocStudent(query) {
  return request({
    url: '/dorm/dormpreallocstudent/stat',
    method: 'get',
    params: query
  })
}

export function getDormPreallocStudent(id) {
  return request({
    url: '/dorm/dormpreallocstudent/get?id=' + id,
    method: 'get'
  })
}

export function addDormPreallocStudent(data) {
  return request({
    url: '/dorm/dormpreallocstudent/add',
    method: 'post',
    data: data
  })
}

export function editDormPreallocStudent(data) {
  return request({
    url: '/dorm/dormpreallocstudent/edit',
    method: 'post',
    data: data
  })
}

export function delDormPreallocStudent(ids) {
  return request({
    url: '/dorm/dormpreallocstudent/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
