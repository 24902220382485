import request from '@/utils/request'

// 查询流程详细
export function getTaskData(resourceId,taskId,processId) {
  return request({
    url: '/bpm/task/getData?taskId=' + taskId + "&processId="+processId,
    method: 'get'
  })
}
// 发起流程
export function completeTask(code,data) {
  return request({
    url: '/bpm/task/complete/'+code,
    method: 'post',
    data: data
  })
}
//驳回任务
export function rejectTask(data) {
  return request({
    url: '/bpm/task/reject',
    method: 'post',
    data: data
  })
}
//转派任务
export function transferTask(data) {
  return request({
    url: '/bpm/task/transfer',
    method: 'post',
    data: data
  })
}
//停止任务
export function stopTask(data) {
  return request({
    url: '/bpm/task/stop',
    method: 'post',
    data: data
  })
}
// 流程实例任务
export function getProcessTaskList(processId) {
  return request({
    url: '/bpm/task/list?processId='+processId,
    method: 'get'
  })
}
//撤回任务
export function withdrawTask(data) {
  return request({
    url: '/bpm/task/withdraw',
    method: 'post',
    data: data
  })
}
//批量同意任务
export function agreeBatchTask(data) {
    return request({
      url: '/bpm/task/agree_batch',
      method: 'post',
      data: data
    })
  }