import request from '@/utils/request'

export function listDormBed(query) {
  return request({
    url: '/dorm/dormbed/list',
    method: 'get',
    params: query
  })
}

export function getDormBed(id) {
  return request({
    url: '/dorm/dormbed/get?id=' + id,
    method: 'get'
  })
}

export function addDormBed(data) {
  return request({
    url: '/dorm/dormbed/add',
    method: 'post',
    data: data
  })
}

export function editDormBed(data) {
  return request({
    url: '/dorm/dormbed/edit',
    method: 'post',
    data: data
  })
}

export function delDormBed(ids) {
  return request({
    url: '/dorm/dormbed/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}
