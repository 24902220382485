<template>
  <div class="success">
    <h3>基础信息</h3>

    <el-row :gutter="10" style="width: innerWidth">
      <el-col :xs="24" :sm="24" :md="24" :lg="1" :xl="1">
        <h3 style="opacity: 0">基础信息</h3>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
        <!-- <img
          src="@/assets/theme/background-2.png"
          style="width: 5vw; height: 15vh"
        /> -->
        <img
          :src="form.photo"
          v-if="form.photo"
          style="width: 5vw; height: 6.7vw"
        />
        <img
          src="@/assets/image/acquiesce.jpg"
          v-else
          style="width: 5vw; height: 6.7vw"
        />
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="21">
        <div class="success-3-top">
          <ul class="success-3-top-ul">
            <li><span>学号：</span> {{ form.no }}</li>
            <li><span>民族：</span> {{ form.ethnicity }}</li>
            <li>
              <span>身份证号：</span>
              {{ form.idcard }}
            </li>
          </ul>
          <ul class="success-3-top-ul">
            <li><span> 姓名：</span>{{ form.name }}</li>
            <li><span>出生日期：</span> {{ form.birthday }}</li>
            <li v-if="form.ext && form.ext.photo">
              <span> 联系电话：</span>{{ form.ext.photo }}
            </li>
            <li v-else><span>联系电话：</span> 无</li>
          </ul>
          <ul class="success-3-top-ul">
            <li><span>性别：</span> {{ form.gender == "1" ? "男" : "女" }}</li>
            <li v-if="form.ext && form.ext.originaddr">
              <span>生源地：</span> {{ form.ext.originaddr }}
            </li>
            <li v-else><span>生源地：</span> 无</li>
            <li><span>政治面貌：</span> {{ form.politics }}</li>
          </ul>
          <ul class="success-3-top-ul">
            <li><span>学院：</span> {{ form.dept_name }}</li>
            <li v-if="form.cwh"><span>床位号：</span> {{ form.cwh }}</li>
            <li v-else><span>床位号：</span> 无</li>
            <li><span>家庭地址：</span> 无</li>
          </ul>
          <ul class="success-3-top-ul">
            <li><span>专业：</span> {{ form.major_name }}</li>
            <li style="display: flex; align-items: start">
              <span> 请假状态：</span>
              <div :style="`color:${Qj.label_color}`">{{ Qj.status }}</div>
            </li>
            <li></li>
          </ul>
        </div>
      </el-col>
    </el-row>

    <div class="line"></div>

    <el-row type="flex" justify="space-between" style="margin-top: 3vh">
      <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <h3>行为分析</h3>
      </el-col>

      <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="select-right">
          <!-- <ul class="selsect-semester">
            <li
              v-for="(item, index) in semester"
              :key="index"
              :class="{ active: index == semesterIndex }"
              @click="changeSemester(index)"
              style="cursor: pointer"
            >
              {{ item }}
            </li>
          </ul>
          <el-date-picker
            v-model="value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 321px; cursor: pointer"
          >
          </el-date-picker> -->
        </div>
      </el-col>
    </el-row>

    <el-row type="flex" justify="space-around" style="margin-top: 30px">
      <el-col :span="6" v-for="(item, index) in behavior_analysis" :key="index">
        <div
          style="
            text-align: center;
            font-size: 30px !important;
            margin-bottom: 10px;
            cursor: pointer;
          "
          @click="changeQj(item)"
        >
          {{ item.value }}
        </div>
        <div style="text-align: center; font-size: 14px; cursor: pointer">
          {{ item.name }}
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between" class="options">
      <el-col :xs="20" :sm="20" :md="20" :lg="22" :xl="22">
        <el-radio-group v-model="record" style="margin-bottom: 30px">
          <el-radio-button label="TableQj">请假记录</el-radio-button>
          <el-radio-button label="TableXj">销假记录</el-radio-button>
          <el-radio-button label="TableCw">床位记录</el-radio-button>
          <el-radio-button label="TableMj">门禁记录</el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :xs="4" :sm="4" :md="4" :lg="2" :xl="2">
        <span @click="changePage"
          >查看更多 <i class="el-icon-d-arrow-right"></i>
        </span>
      </el-col>
    </el-row>

    <!-- 表格 -->
    <component :is="record" class="component" :no="ids"></component>
  </div>
</template>

<script>
import { getUserByNo } from "@/api/core/system/user";
import { getDicts } from "@/api/core/data/dict/data";
import TableQj from "./TableQj.vue";
import TableXj from "./TableXj.vue";
import TableCw from "./TableCw.vue";
import TableMj from "./TableMj.vue";
import { getProcess } from "@/api/dorm/dorm_students";

import {
  listStuStudent,
  getStuStudent,
  addStuStudent,
  editStuStudent,
  delStuStudent,
  syncAccount,
  syncAccountProgress,
} from "@/api/stu/stu_student";

import {
  listDormBedHistory,
  getDormBedHistory,
  addDormBedHistory,
  editDormBedHistory,
  delDormBedHistory,
  getaccessControl,
  lookaccessControl,
  getleave,
  lookleave,
} from "@/api/dorm/dorm_bed_history";

export default {
  props: {
    ids: {
      type: String,
      default: "",
    },
  },
  components: { TableQj, TableCw, TableMj, TableXj },
  data() {
    return {
      innerWidth: 0,
      value1: "",
      // 学期
      semester: ["本学期", "本学年"],
      semesterIndex: 0,
      behavior_analysis: [
        {
          name: "请假次数",
          value: 0,
          key: "qjcs",
          router: 144,
          formName: "form61_no",
        },
        // {
        //   name: "逾期销假次数",
        //   value: 0,
        //   key: "yqxjcs",
        // },
        {
          name: "晚宿晚归次数",
          value: 0,
          key: "wswgcs",
          router: 142,
          formName: "form65_no",
        },
        {
          name: "违规违纪次数",
          value: 0,
          key: "wgwjcs",
          router: 157,
          formName: "form1_no",
        },
        {
          name: "调宿次数",
          value: 0,
          key: "tscs",
          router: 134,
          formName: "formstu_no",
        },
        {
          name: "外宿申请次数",
          value: 0,
          key: "wssqcs",
          router: 136,
          formName: "formstu_no",
        },
      ],

      // 记录
      record: "TableQj",
      form: {},

      // 请假
      Qj: {
        status: "",
        label_color: "",
      },
    };
  },
  created() {
    this.getUserInfo();
    this.getList();
    this.getProcess();
  },

  methods: {
    // 获取用户信息
    getUserInfo() {
      let data = {
        pageNum: 1,
        pageSize: 10,
        no: this.ids,
      };
      listStuStudent(data)
        .then((response) => {
          this.form = response.data.list[0];
          this.init();
        })
        .catch((e) => {
          // loading.close();
        });
    },
    convertTimestampToDate2(timestamp) {
      // 创建一个新的 Date 对象
      var date = new Date(timestamp * 1000); // 时间戳通常是秒级的，所以需要乘以 1000 转换为毫秒

      // 获取日期各部分
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() 是从 0 开始的，所以要加 1
      var day = ("0" + date.getDate()).slice(-2);

      // 构建日期字符串
      return `${year}年${month}月${day}日`;
    },

    init() {
      this.form.birthday = this.convertTimestampToDate2(this.form.birthday);
      this.form.ext = JSON.parse(this.form.ext);
      if (!this.form.ext.bed_name) return;
      this.form["cwh"] =
        this.form.ext.xq_name +
        "-" +
        this.form.ext.building_name +
        "-" +
        this.form.ext.room_name +
        "-" +
        this.form.ext.bed_name;
      if (this.form.no) {
        // getUserByNo(this.form.no).then((res) => {
        //   console.log("用户头像数据 ==> ", res);
        //   this.form["avatar"] = res.data.avatar;
        // });

        getDicts("stu_ethnicity").then((res) => {
          let ethnicityOptions = res.data.values;
          ethnicityOptions.map((item) => {
            if (item.key == this.form.ethnicity) {
              this.form.ethnicity = item.value;
            }
          });
        });
        getDicts("stu_politics").then((res) => {
          let ethnicityOptions = res.data.values;
          ethnicityOptions.map((item) => {
            if (item.key == this.form.politics) {
              this.form.politics = item.value;
            }
          });
        });
      }
    },

    changeSemester(index) {
      this.semesterIndex = index;
    },

    // 获取信息
    getProcess() {
      let str = {
        __max_count__: 1000, //返回最大行数
        stuno: this.ids,
      };
      let data = {
        id: 360,
        params: JSON.stringify(str),
      };

      getProcess(data).then((res) => {
        let obj = res.data[0];
        if (res.code == 0 && res.data.length) {
          for (let i in obj) {
            this.behavior_analysis.map((item, index) => {
              if (i == item.key) {
                item.value = obj[i];
              }
            });
          }
        }
      });
    },

    // 获取请假状态
    getList() {
      let str = {
        no: {
          like: this.ids,
        },
        id: {
          orderby: "desc",
        },
      };
      let data = {
        mid: "56392148113413",
        page: 1,
        psize: 10,
        qstr: JSON.stringify(str),
      };

      getleave(data).then((response) => {
        let status = response.data.list[0].qstatus;
        let arr = [
          { name: "1", value: "未请假", label_color: "rgba(11, 11, 11, 1)" },
          {
            name: "2",
            value: "申请待处理",
            label_color: "rgba(30, 144, 255, 1)",
          },
          { name: "3", value: "请假中", label_color: "rgba(144, 238, 144, 1)" },
          { name: "4", value: "销假逾期", label_color: "rgba(255, 17, 0, 1)" },
          {
            name: "5",
            value: "销假确认中",
            label_color: "rgba(30, 144, 255, 1)",
          },
        ];
        arr.map((item) => {
          if (item.name == status) {
            this.Qj.status = item.value;
            this.Qj.label_color = item.label_color;
          }
        });
      });
    },

    changePage() {
      if (this.record == "TableQj") {
        this.$router.push({ path: "/bpm/tabledata/list/144" });
      } else if (this.record == "TableCw") {
        this.$router.push({ path: "/dorm/dormbedhistory" });
      } else if (this.record == "TableMj") {
        this.$router.push({ path: "/form/data/index/57235720359941" });
      } else if (this.record == "TableXj") {
        this.$router.push({ path: "/bpm/tabledata/list/153" });
      }
    },

    changeQj(item) {
      // 学号名称
      let name = item.formName;
      if (name) {
        this.$router.push({
          path: "/bpm/tabledata/list/" + item.router,
          query: {
            [name]: this.ids,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.success-3-top {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

li > span {
  display: inline-block;
  width: 82px;
  text-align: right;
}
.success-3-top-ul {
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  li {
    // width: 13vw;
    // overflow: hidden;
    white-space: nowrap;
    // text-overflow: ellipsis;
  }
}
.success-3-bottom {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 10px;
}
.line {
  width: 100%;
  height: 1px;
  background: #f6f8f9;
  margin-top: 3vh;
}
.select-right {
  display: flex;
  justify-content: space-between;
}
.selsect-semester {
  width: 158px;
  height: 30px;
  display: flex;
  border: 1px solid #0894ec;
  line-height: 30px;
  color: #0894ec;
  font-weight: bold;
  & > li {
    flex: 1;
    text-align: center;
    &.active {
      background: #0894ec;
      color: white;
    }
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 321px !important;
}
.options {
  margin-top: 6vh;
  position: relative;
  left: 18px;
  top: 30px;
  z-index: 9;
  // width: 96%;
}

.component {
  transform: translateY(-50px);
}
</style>
