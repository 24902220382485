<template>
  <container-item-wrapper :widget="widget">
   <div class="association-form-item">
  <div class="header">
    <el-button type="primary" @click="change">选择</el-button>
  </div>
    <el-row
      :gutter="widget.options.gutter"
      class="form-container"
      :class="[customClass]"
      :ref="widget.id"
      v-show="!widget.options.hidden"
    >
      <template v-for="(colWidget, colIdx) in widget.cols">
        <grid-col-item
          :widget="colWidget"
          :key="colIdx"
          :parent-list="widget.cols"
          :index-of-parent-list="colIdx"
          :parent-widget="widget"
          :col-height="widget.options.colHeight"
          :sub-form-row-id="subFormRowId"
          :sub-form-row-index="subFormRowIndex"
          :sub-form-col-index="subFormColIndex"
        >
          <!-- 递归传递插槽！！！ -->
          <template
            v-for="slot in Object.keys($scopedSlots)"
            v-slot:[slot]="scope"
          >
            <slot :name="slot" v-bind="scope" />
          </template>
        </grid-col-item>
      </template>
    </el-row>
    <el-dialog
      :title="widget.options.form_type == 1 ? '表单数据' : '流程数据'"
      :visible.sync="show"
      width="80%"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div v-if="widget.options.form_type == 1">
        <form-table
          ref="formTable"
          :config="widget.options"
          @change="checkboxChangeEvent"
        ></form-table>
      </div>
      <div v-if="widget.options.form_type == 2">
        <flow-table
          ref="flowTable"
          :config="widget.options"
          @change="checkboxChangeEvent"
        ></flow-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          :disabled="checkedList.length !== 1"
          @click="onSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
   </div>
  </container-item-wrapper>
</template>

<script>
import emitter from "@/vform/utils/emitter";
import i18n from "../../../utils/i18n";
import refMixin from "../../../components/form-render/refMixin";
import ContainerItemWrapper from "./container-item-wrapper";
import GridColItem from "./grid-col-item";
import containerItemMixin from "./containerItemMixin";
import FlowTable from "@/components/jfFlowTable/flowTable";
import FormTable from "@/components/jfFormTable/formTable";
export default {
  name: "form-item-123123", //grid-item跟VueGridLayout全局注册组件重名，故特殊处理！！
  componentName: "ContainerItem",
  mixins: [emitter, i18n, refMixin, containerItemMixin],
  components: {
    ContainerItemWrapper,
    GridColItem,
    FlowTable,
    FormTable
  },
  props: {
    widget: Object,

    subFormRowIndex: {
      /* 子表单组件行索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormColIndex: {
      /* 子表单组件列索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormRowId: {
      /* 子表单组件行Id，唯一id且不可变 */ type: String,
      default: "",
    },
  },
  data() {
    return {
      checkedList: [],
      show: false,
    };
  },
  watch: {
    widget: {
      handler(val) {
      console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
  inject: ["refList", "sfRefList", "globalModel",'setRefreshData'],
  created() {
    this.initRefList();
  },
  mounted() {},
  beforeDestroy() {
    this.unregisterFromRefList();
  },
  methods: {
    handleClose() {
      this.show = false;
    },
    //打开关联表单
    change(e) {
    //   this.show = true;
      // if(!this.widget.options.is_form){
      //     return
      // }
      // this.rowIndex = e;
      this.show = true;
      let mid = this.widget.options.form_data.id;
      if (this.widget.options.form_type == 1) {
          this.$nextTick(() => {
              this.$refs["formTable"].init(mid);
          });
      } else if (this.widget.options.form_type == 2) {
          this.$nextTick(() => {
              this.$refs["flowTable"].init(mid);
          });
      }
    },
    checkboxChangeEvent(e) {
      this.checkedList = e;
    },
    // 确认按钮赋值
    onSubmit() {
      let val = this.checkedList[0];
      let obj={}
      this.widget.cols.forEach(item=>{
        item.widgetList.forEach(widget=>{
            widget.options.defaultValue = val[widget.options.name]
            obj[widget.options.name] =val[widget.options.name]
            this.$set(this.globalModel.formModel,widget.options.name,val[widget.options.name])
        })
        
      })
      this.setRefreshData(obj)
      this.widget.cols=JSON.parse(JSON.stringify(this.widget.cols))
    //   this.globalModel.formModel = Object.assign(this.globalModel.formModel,obj)

    //   let widgetList = this.widget.widgetList;
    //   let obj = {};
    //   let subFormValues = JSON.parse(
    //     JSON.stringify(this.globalModel.formModel[this.widget.options.name])
    //   );
    //   widgetList.forEach((item) => {
    //     obj[item.options.name] = val[item.options.name];
    //   });
    //   subFormValues[this.rowIndex] = obj;
    //   this.setSubFormValues(subFormValues);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.association-form-item{
    padding: 5px 5px 0 5px;
    border: 1px dashed #d3d3d3;
    .header{
        // padding-bottom: 5px;
        display: flex;
        justify-content: flex-end;
        // border-bottom: 1px solid #d3d3d3;
    }
}
</style>
