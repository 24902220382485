<template>
  <div class="app-container">
    <el-row :gutter="20"></el-row>
    <!--数据-->
    <el-col :span="24 - leftrolspan" :xs="24"> </el-col>
    <!-- 渲染查询表单 -->

    <VFormRender
      v-if="queryformData"
      class="query-form-data"
      ref="queryform"
      :formJson="queryformData"
    ></VFormRender>

    <el-form inline v-if="queryformData && queryformData.widgetList.length > 0">
      <!-- label-width="68px" -->
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <jf-table
      :tree-config="treeConfig"
      :loading="loading"
      :query-params="queryParams"
      :toolbar-config="tableToolbar"
      :columns="tableColumn"
      :data="dataList"
      @sort-change="sortChange"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
      @toggle-tree-expand="toggleExpandChangeEvent"
      @cell-dblclick="Oncelldblclick"
      @page-change="handleSizeChange"
      :total="total"
      :filterBtn="switchshowbtn('filter')"
      :sortBtn="switchshowbtn('sort')"
      @change="tableChange"
      :dictsData="DictsData"
      :DsData="DsData"
      :defaultoprWidth="250"
      :filterTemplateList="filterTemplateList"
      :isFilterVisible="isFilterVisible"
    >
      <template slot="toolbar_btn_left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermiZc="['/form/data/add/' + mid]"
          v-if="switchshowbtn('add')"
          >新增</el-button
        >
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermiZc.all="['/form/data/get/' + mid, '/form/data/edit/' + mid]"
          v-if="switchshowbtn('edit')"
          >修改</el-button
        >
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermiZc="['/form/data/del/' + mid]"
          v-if="switchshowbtn('delete')"
          >删除</el-button
        >
        <el-button
          v-if="switchshowbtn('import')"
          type="warning"
          size="mini"
          @click="handleImport(1)"
          v-hasPermiZc="['/form/data/import/' + mid]"
        >
          <i class="iconfont icon-daoru_o"></i>
          导入
        </el-button>

        <el-button
          v-if="switchshowbtn('export')"
          class="btn-orange"
          size="mini"
          @click="handleImport(2)"
          v-hasPermiZc="['/form/data/export/' + mid]"
        >
          <i class="iconfont icon-daochu_o"></i>
          导出
        </el-button>

        <el-button
          v-for="item in toolbtninfo[mid]"
          :key="item.name"
          class="btn-orange"
          size="mini"
          @click="handleToolBtnAction(item)"
          v-hasPermiZc="['/form/data/export/' + mid]"
        >
          <i class="iconfont icon-daochu_o"></i>
          {{ item.label }}
        </el-button>

        <el-dropdown @command="handleCommand">
          <el-button size="mini" type="text" style="margin-left: 10px">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="operationLogOpen"
              >操作记录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>

      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          v-for="item in rowbtninfo[mid]"
          :key="item.name"
          size="mini"
          type="text"
          icon="el-icon-info"
          @click="handleBtnAction(item, row)"
          >{{ item.label }}
        </el-button>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click="Oncelldblclick({ row })"
          v-hasPermiZc="['/form/data/get/' + mid]"
          v-if="switchshowbtn('get')"
          >查看
        </el-button>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-edit"
          @click="handleUpdate(row)"
          v-hasPermiZc="['/form/data/edit/' + mid]"
          v-if="switchshowbtn('edit')"
          >修改
        </el-button>

        <el-button
          size="mini"
          type="text"
          icon="el-icon-delete"
          v-hasPermiZc="['/form/data/del/' + mid]"
          @click="handleDelete(row)"
          v-if="switchshowbtn('delete')"
          >删除
        </el-button>
      </template>
      <template slot="status" slot-scope="{ row }">
        <el-switch
          v-model="row.status"
          active-value="1"
          inactive-value="0"
          @change="handleStatusChange(row)"
        ></el-switch>
      </template>
      <template slot="roles" slot-scope="{ row }">
        <span v-for="(item, index) of row.roles" :key="index">
          {{ item.name }}
        </span>
      </template>
      <template slot="depselect" slot-scope="{ row }">
        {{ getName(row, "dep") }}
      </template>
      <template slot="personnelselect" slot-scope="{ row }">
        {{ getName(row, "personnelselect") }}
      </template>
      <template
        :slot="item.slots.default"
        slot-scope="{ row }"
        v-for="item in columnSlots"
      >
        <div v-html="getFun(item, row)"></div>
      </template>
    </jf-table>

    <!-- 添加或修改对话框  数据 -->
    <el-dialog
      :title="title"
      :visible.sync="open"
      :width="
        formData && formData.formConfig && formData.formConfig.dialogWidth
          ? formData.formConfig.dialogWidth
          : '1000px'
      "
      :close-on-click-modal="false"
      append-to-body
      :before-close="cancel"
      custom-class="scrolldialog"
    >
      <!-- <generate-form
                ref="form"
                :key="1"
                :conf="formData"
                v-if="formData != null"
            /> -->
      <!-- :isReadOnly="isview" -->
      <VFormRender
        v-if="formData != null"
        ref="form"
        :disabledMode="isview"
        :formJson="formData"
        :userInfo="userInfo"
      ></VFormRender>

      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isview" type="primary" @click="submitForm"
          >确 定</el-button
        >
        <el-button @click="cancel">返 回</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 导入导出 -->
    <jf-import
      :show.sync="importOpen"
      v-if="importOpen"
      :mid="mid"
      :type="importType"
      :columns="tableColumn"
      :isfinish="isupladfinish"
      :downloadtplurl="downloadtplurl"
      @importsubmit="importsubmit"
      :importError="importError"
      :exportTotal="total"
      @exportSubmit="exportSubmit"
      @saveExportTemplate="saveExportTemplate"
      :exportTemplateList="exportTemplateList"
    ></jf-import>
    <!-- 操作记录 -->
    <jf-operation-log
      :show.sync="operationLogOpen"
      v-if="operationLogOpen"
    ></jf-operation-log>

    <!-- 导入提示 -->
    <el-dialog
      title="导入提示"
      :close-on-click-modal="false"
      :visible.sync="showerrinfo"
      width="750px"
      append-to-body
      custom-class="scrolldialog"
    >
      <p class="erinfo" v-for="(item, idx) in upload_errinfo" :key="idx">
        {{ item }}
      </p>
    </el-dialog>

    <el-dialog title="查看二维码" :visible.sync="showviewqrcodeinfourl">
      <img :src="viewqrcodeinfourl" class="eqqrcode" />
    </el-dialog>

    <el-dialog
      title="查看"
      height="90%"
      width="80%"
      :visible.sync="showformdialog"
    >
      <!-- <ViewForm v-if="!isinviewform" :mid="showformdialogmid"></ViewForm> -->
      <form-data
        ref="formdatadialog"
        :propsmid="showformdialogmid"
        :propsqueryparam="showformdialogqueryparam"
      ></form-data>
    </el-dialog>

    <el-dialog title="查看" :visible.sync="showstudetail" width="80%">
      <div>
        <SuccessVue :ids="stu_no" :key="successKey"></SuccessVue>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="successShow = false">取 消</el-button> -->
        <el-button type="primary" @click="showstudetail = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
<el-dialog title="选择学生" :visible.sync="opendia_selstuinfo" width="80%">
      <div>
        <ListStu :openfrom="'formdata'" @onSelect="onSelect"></ListStu>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="successShow = false">取 消</el-button> -->
        <el-button type="primary" @click="opendia_selstuinfo = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>

    
  </div>
</template>

<script>
// import GenerateForm from "@/components/form/DynamicForm/GenerateForm";
// import { execModel } from "@/api/core/data/datasource/model";
import { getToken } from "@/utils/auth";
import QRCode from "qrcode";
import Treeselect from "@riophae/vue-treeselect";
import * as Formx from "@/api/form/data";
// import * as FormM from "@/api/form/model";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

// import { listStuStudent } from "@/api/stu/stu_student";
import VFormRender from "@/vform/components/form-render/index.vue";

import jfImport from "@/components/jfimport/jfimport";
import JfOperationLog from "@/components/jfoperationlog/jfoperationlog";
// import { deepClone } from "@/vform/utils/util";

import { getUserProfile } from "@/api/core/system/user";
import SuccessVue from "@/views/dorm/stu_student/success";
import { form } from "@/mixins/form.js";
import ListStu from "@/views/stu/stu_student/index"

const Base64 = require("js-base64").Base64;

export default {
  mixins: [form],
  name: "form-data",
  components: {
    Treeselect,
    VFormRender,
    jfImport,
    JfOperationLog,
    SuccessVue,
    ListStu
  },
  props: {
    propsmid: {
      //prop传入的表单id
      type: String,
      default: null,
    },
    propsqueryparam: {
      //prop传入的查询参数
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showformdialog: false,
      showformdialogmid: "",
      showformdialogqueryparam: null,
      isinviewform: false,
      showviewqrcodeinfourl: false,
      viewqrcodeinfourl: undefined,
      rowbtninfo: {
        //学生综合信息
        56392148113413: [
          {
            label: "查看",
            name: "studetail",
          },
        ],
        //下载诊断报告
        55670720532485: [
          {
            label: "下载报告",
            name: "downreport",
          },
        ],
        //诊断报告
        50691341885445: [
          {
            label: "查看报告",
            name: "opendialog",
            mid: "55670720532485",
            queryparam: {
              hzid: "{{id}}",
            },
          },
        ],
        // 排查任务完成情况
        33393540468741: [
          {
            label: "完成情况",
            name: "opendialog",
            mid: "35866888081413",
            queryparam: {
              rwid: "{{id}}",
            },
          },
        ],
        //课程记录
        26500254121989: [
          {
            label: "学习记录",
            name: "opendialog",
            mid: "26261399183365",
            queryparam: {
              kcid: "{{id}}",
            },
          },
        ],
        //设备设施
        27324141545477: [
          {
            label: "二维码",
            name: "qrcode",
            url: "/pages-sub/device/info",
            title: null,
            extparam:
              "&xjId=117&byId=116&wxId=115&path=/pages-sub/device/index",
          },
        ],
        //防洪与应急设施
        27328564649989: [
          {
            label: "二维码",
            name: "qrcode",
            url: "/pages-sub/device/info",
            title: "防洪",
            extparam:
              "&xjId=10012&byId=127&wxId=129&path=/pages-sub/emergency/device",
          },
        ],
        //消防设备
        27331369353221: [
          {
            label: "二维码",
            name: "qrcode",
            url: "/pages-sub/device/info",
            title: "消防",
            extparam:
              "&xjId=10013&byId=126&wxId=128&path=/pages-sub/fire-fighting/device",
          },
        ],
        //应急设备
        27329303818245: [
          {
            label: "二维码",
            name: "qrcode",
            url: "/pages-sub/device/info",
            title: "应急",
            extparam:
              "&xjId=10014&byId=125&wxId=124&path=/pages-sub/emergency/emergency-device",
          },
        ],
        // //课程管理
        // 26500254121989: [
        //     {
        //         label: "学习记录",
        //         name: "learrec",

        //     },
        // ],
      },
      toolbtninfo: {
        53870020272133: [
          {
            label: "生成",
            name: "createrank",
          },
        ],
        27324141545477: [
          {
            label: "下载二维码",
            name: "downqrcode",
          },
        ],
        //防洪与应急设施
        27328564649989: [
          {
            label: "下载二维码",
            name: "downqrcode",
          },
        ],
        //消防设备
        27331369353221: [
          {
            label: "下载二维码",
            name: "downqrcode",
          },
        ],
        //应急设备
        27329303818245: [
          {
            label: "下载二维码",
            name: "downqrcode",
          },
        ],
      },

      org_optionItems: {},
      istree: false,
      hidequeryparamform: false,
      exportTemplateList: [],
      listconf: null,
      importError: false,
      downloadtplurl: "",
      upload_errinfo: null,
      showerrinfo: false,
      isupladfinish: false,
      //导入导出弹窗
      importOpen: false,
      //导入导出类型
      imporType: 1,
      //操作记录
      operationLogOpen: false,
      //功能id
      mid: 0,
      queryformData: null, //查询表单数据
      queryformDataorgstr: "",

      formData: null, //表单数据
      isview: false,
      model_info: {},
      child_model_info: {},
      childcollist: [],
      collist: [],
      LeftTreeData: [],
      LeftTreeDataProc: {},
      treeOptions: [],
      treeselectprops: {},
      treeConfig: {
        trigger: "row",
        expandRowKeys: [],
      },
      TreeAttr: {},

      //左侧分栏比例
      leftrolspan: 0,
      //checkbox 的数据
      CheckBoxData: {},

      area_dist: {},
      province_dist: {},
      city_dist: {},

      //上传的文件列表
      upload_fileList: {},

      //上传文件参数
      upload: {
        headers: {
          Authorization: getToken(),
        },
        url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
      },

      //编辑器配置
      ueConfig: {
        // 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口
        serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
        enableAutoSave: true,
        elementPathEnable: false,
        wordCount: false,
        UEDITOR_HOME_URL: "/ueditor/",
      },

      //图片预览地址
      dialogImageUrl: "",
      //图片预览对话框
      dialogVisible: false,

      loadingstu: false,
      openselstu: false,

      // 工具栏
      tableToolbar: {
        perfect: false,
        zoom: true,
        custom: false,
        refresh: {
          query: this.handleQuery,
        },
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      // 数据列
      tableColumn: [
        {
          type: "checkbox",
          width: 60,
          fixed: "left",
        },
        {
          field: "id",
          title: "ID",
          width: 100,
          fixed: "left",
        },

        // {
        // 	field: "",
        // 	title: "操作",
        // 	resizable: false,
        // 	width: 180,
        // 	fixed: "right",
        // 	align: "center",
        // 	slots: {
        // 		default: "defaultopr"
        // 	},
        // },
      ],

      orgcolumnList: [],
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      totalstu: 0,
      // 表格数据
      dataList: [],
      // 弹出层标题
      title: "",
      // 快速检索数据
      Quitfilter_data: {},
      // 快速检索数据 字典类型
      Quitfilter_keys: [],
      // 是否显示弹出层
      open: false,
      // 机构名称
      deptname: undefined,
      // 默认密码
      initPassword: undefined,

      //表单
      form: {},
      defaultProps: {
        children: "children",
        label: "name",
      },

      // 表单校验
      rules: {},
      userInfo: {},
      filterTemplateList: [],
    };
  },

  computed: {
    columnSlots: function () {
      var slots = this.tableColumn.filter((item) => {
        return item.slots;
      });
      return slots;
      // console.log(this.tableColumn.filter((item) => {return item.slots}))
    },
  },
  watch: {
    // 根据名称筛选机构树
    deptname(val) {
      this.$refs.tree.filter(val);
    },
  },
  beforeCreate() {},

  created() {
    this.initData();
    this.subscribeComponentsMsg("opendia_selstuinfo", (msg, data) => {
        console.log(" opendia_selstuinfo data", data);
            //弹出选择学生窗口
            this.opendia_selstuinfo =true
            
            
         
            
        });

  

  },
  methods: {
    onSelect(data){
        // console.log("onSelect data", data);
        if(!data|| data.length==0){
            return false
        }
        let onestu = data[0]
        let stuinfo = {}
        Object.keys(onestu).forEach((kn)=>{
            stuinfo["stu_"+kn] = onestu[kn]
        })

        this.$refs.form.setFormData(stuinfo)

        this.opendia_selstuinfo =false

    },
    initData() {
      this.mid = this.$route.params.mid;
      if (this.$route.path.indexOf("/form/viewdata/") > -1) {
        this.isinviewform = true;
      }

      //如果属性传入的mid不为空，则取之
      if (this.propsmid != null) {
        this.mid = this.propsmid;
      }
      this.downloadtplurl = "/form/data/downtpl/" + this.mid; //下载模版地址
      this.queryParams.sortinfo = this.defsort;

      if (this.mid) {
        this.getforminfo(this.mid);
        this.getFilterTemplateList();

        let query = this.$route.query;
        // console.log("query ==>", query);
        if (Object.keys(query).length !== 0) {
          this.reset(); // 解决跳转不更新问题
          this.loading = true;
          setTimeout(() => {
            for (let i in query) {
              this.$refs.queryform.setFieldValue(i, query[i], false);
            }
            this.loading = true;
            this.handleQuery();
          }, 1500);
        }
      }

      this.getUser();
    },

    getQRcode(text) {
      QRCode.toDataURL(
        text,
        { errorCorrectionLevel: "L", margin: 2, width: 512 },
        (err, url) => {
          if (err) throw err;
          this.viewqrcodeinfourl = url;
          // this.QRImgUrl = url;
          // console.log("url", url);
          // window.open(url);
        }
      );
    },

    getUser() {
      getUserProfile().then((response) => {
        this.userInfo = response.data.user;
      });
    },
    getName(row) {
      console.log(row, "测试");
    },

    exportSubmit(fieldlist) {
      var that = this;
      var tfa = [];
      for (var i in fieldlist) {
        var byname = "";
        if (fieldlist[i].byname != null) {
          byname = fieldlist[i].byname;
        }
        tfa.push(fieldlist[i].field + "=" + byname);
      }
      var durl = Formx.FormDataDownloadUrl(this.mid, this.queryParams, tfa);
      // let durl="/form/data/download/"+ this.mid
      console.log("exportSubmit durl", durl);
      try {
        this.downloadbyurl(durl, null, function (ers) {
          //  console.log("downloadbyurl  ", ers);
          if (ers.currentTarget.status != 200) {
            that.msgError("导出错误");
          }
        });
      } catch (error) {
        // console.log(chalk.red(error));
      }
    },
    importsubmit(rows) {
      console.log("importsubmit rows", rows);
      this.importError = false;
      //开始导入
      Formx.FormDataImport(this.mid, rows.datalist, rows.importoption)
        .then((rs) => {
          if (rs.data.row.Failmsg != null && rs.data.row.Failmsg != "") {
            this.upload_errinfo = rs.data.row.Failmsg.split("|");
            this.showerrinfo = true;
            this.importError = true;
          } else {
            this.isupladfinish = true;
            this.msgSuccess("导入成功");
            this.getList(this.mid);
          }
        })
        .catch((error) => {
          this.importError = true;
        });
    },
    //导入导出
    handleImport(type) {
      this.importError = false;
      this.isupladfinish = false;
      this.importType = type;
      this.importOpen = true;
      if (type == 2) {
        this.getexportTemplateList();
      }
    },
    handleCommand(e) {
      //   console.log(e);
      this[e] = true;
    },

    getFun(item, row) {
      //   console.log(item, row);
      return this[item.slotsFun.funName](
        item.slotsFun.dt,
        row[item.slotsFun.key],
        item.slotsFun.key
      );
    },

    //查看
    Oncelldblclick(row) {
      this.handleUpdate(row.row, "查看");
      this.isview = true;
    },

    getTreeselect() {
      if (this.model_info.Category != "4" && this.model_info.Category != "5") {
        return false;
      }
      this.treeOptions = [];
      let opt = {
        id: 0,
        children: [],
      };
      opt[this.TreeAttr.tree_name] = "(无上级)";

      if (this.model_info.Category == "5") {
        opt.children = this.LeftTreeData;
      } else {
        opt.children = this.dataList;
      }

      this.treeOptions.push(opt);
      // console.log(this.treeOptions);
    },

    /** 转换菜单数据结构 */
    Treenormalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node[this.TreeAttr.tree_name],
        children: node.children,
      };
    },

    /** 查询数据     */
    getList(mid) {
      this.loading = true;
      if (this.propsqueryparam != null) {
        for (let field in this.propsqueryparam) {
          let v = this.propsqueryparam[field];
          this.queryParams.fieldval[field] = v;
        }
      }
      console.log("提交前查询信息 ===> ", this.queryParams);
      Formx.FormxShowList(mid, this.queryParams)
        .then((response) => {
          this.loading = false;
          console.log(
            " response.data this.treeConfig",
            response.data,
            this.treeConfig
          );
          //return false;

          if (this.treeConfig.parentField != null) {
            var tlist = [];
            for (let k in response.data.list) {
              let tonef = response.data.list[k];
              tonef["label"] = tonef[this.treeConfig.treeNode];

              tlist.push(JSON.parse(JSON.stringify(tonef)));
            }
            try {
              this.dataList = this.handleTree(
                tlist,
                "id",
                this.treeConfig.parentField
              );
            } catch (error) {
              console.log(" this.dataList error", error);
            }

            console.log(" this.dataList", this.dataList);
          } else {
            setTimeout(() => {
              this.dataList = response.data.list;
            }, 150);
          }

          this.total = response.data.total;
        })
        .catch(() => {
          this.loading = false;
          // this.msgError("加载数据出错,请先检查表单配置!");
        });

      // //如果是左树右表
      // if (this.model_info.Category == "5") {
      //   //加载右表数据
      //   zcdataList(this.child_model_info.Id, this.queryParams)
      //     .then((response) => {
      //       let data = JSON.parse(response.data.list);
      //       this.total = response.data.total;
      //       let rldata = [];
      //       for (var i in data) {
      //         var item = data[i];
      //         item["id"] = parseInt(item["id"]);
      //         item[this.TreeAttr["tree_parent_code"]] = parseInt(
      //           item[this.TreeAttr["tree_parent_code"]]
      //         );
      //         rldata.push(item);
      //       }

      //       //处理树结构表
      //       if (
      //         this.model_info.Category == "4" ||
      //         this.model_info.Category == "5"
      //       ) {
      //         if (this.TreeAttr["tree_code"] == null) {
      //           this.msgError("树编码字段 尚未配置!");
      //           return false;
      //         }
      //         if (this.TreeAttr["tree_parent_code"] == null) {
      //           this.msgError("树父级编码字段 尚未配置!");
      //           return false;
      //         }

      //         rldata = this.handleTree(
      //           rldata,
      //           this.TreeAttr["tree_code"],
      //           this.TreeAttr["tree_parent_code"]
      //         );
      //       }
      //       this.dataList = rldata;
      //       this.loading = false;
      //       if (this.model_info.Category == "4") {
      //         this.$nextTick(() => {
      //           const tableFullData = this.$refs.xGrid.getTableData().fullData;
      //           const rows = [];

      //           for (let j = 0; j < this.treeConfig.expandRowKeys.length; j++) {
      //             const k = this.treeConfig.expandRowKeys[j];
      //             for (let i = 0; i < tableFullData.length; i++) {
      //               const d = tableFullData[i];
      //               if (k == d.id) {
      //                 rows.push(d);
      //                 break;
      //               }
      //             }
      //           }
      //           this.$refs.xGrid.setTreeExpand(rows, true);
      //         });
      //       }
      //     })
      //     .catch(() => {
      //       this.loading = false;
      //       this.msgError("加载数据出错,请先检查是否配置了表单");
      //     });
      // }

      //      listUser(this.addDateRange(this.queryParams, this.dateRange)).then(response => {
      //        let list = []
      //        if(response.data.list) {
      //          response.data.list.forEach(item=>{
      //            item.status = ''+item.status
      //            list.push(item)
      //          })
      //        }
      //        this.single = true;
      //        this.multiple = true;
      //        this.userList = list;
      //        this.total = response.data.total;
      //        this.statusOptions = response.data.statusOptions;
      //        this.genderOptions = response.data.genderOptions;
      //        this.typeOptions = response.data.typeOptions;
      //        this.loading = false;
      //      });
    },

    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    // 取消按钮
    cancel() {
      this.reset();
      this.open = false;
      this.isview = false;
      this.hidequeryparamform = false;
    },
    // 表单重置
    reset() {
      this.form = {};
      this.formData = null;

      this.resetForm("form");
      {
        /* this.resetQuery(); */
      }
    },

    /** 新增按钮操作 */
    handleAdd() {
      //this.reset();
      // this.getTreeselect();
      this.hidequeryparamform = true;
      this.formData = null;
      let tformdata = JSON.parse(this.model_info.Formresource);
      tformdata.formstatus = "add";
      this.$nextTick((_) => {
        this.formData = this.binddicttotablecolumn(tformdata);
      });

      this.open = true;
      this.title = "添加数据";
    },
    handleAdd_lefttree() {
      this.orgcolumnList = this.collist;
      this.reset();
      this.getTreeselect();
      this.open = true;
      this.title = "添加数据";
    },
    isBase64(str) {
      // 使用正则表达式匹配Base64编码格式
      const base64RegExp =
        /^(?:[A-Za-z\d+/]*)(?:[A-Za-z\d+/]{2}==|[A-Za-z\d+/]{3}=)?$/;

      // 检查字符串是否匹配Base64编码格式
      return base64RegExp.test(str);
    },

    /** 修改按钮操作 */
    handleUpdate(row, til) {
      this.reset();
      const id = row.id || this.ids[0];
      Formx.FormxShowGet(this.mid, id).then((response) => {
        this.form.id = response.data.row.id;

        //填充表单值
        let formvalue = null; //表单填写内容
        if (response.data.row.formdata) {
          if (this.isBase64(response.data.row.formdata)) {
            formvalue = Base64.decode(response.data.row.formdata);
          } else {
            formvalue = response.data.row.formdata;
          }
          //   console.log(formvalue);
        } else {
          formvalue = response.data.row;
          this.reformatformdata(formvalue);
        }
        let formData = JSON.parse(this.model_info.Formresource); //表单数据(字段,布局等)
        formData.formstatus = "edit";
        // this.formData = formData;
        this.formData = this.binddicttotablecolumn(formData);

        this.$nextTick((_) => {
          this.$refs.form.setFormData(formvalue);
        });

        this.open = true;
        this.title = til || "修改";
      });
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form
        .getFormData()
        .then((data) => {
          //console.log("forminputdata", data);
          // return false;
          if (this.form.id != null) {
            //修改
            Formx.FormxShowSavedata(this.mid, this.form.id, {
              formdata: JSON.stringify(data),
            })
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                // this.handleQuery();
                this.getList(this.mid);
                this.$refs.form.clearCache();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          } else {
            //新增
            Formx.FormxShowSavedata(this.mid, null, {
              formdata: JSON.stringify(data),
            })
              .then((rs) => {
                this.msgSuccess("操作成功");
                this.cancel();
                this.handleQuery();
                this.$refs.form.clearCache();
              })
              .catch(() => {
                // this.msgError("提交失败!");
              });
          }
        })
        .catch((e) => {
          this.msgError("表单验证不通过,请检查输入");
        });

      return true;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const did = row.id || (this.ids ? this.ids.join(",") : "");
      this.$confirm(this.islogicdelTips, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return Formx.FormxShowDel(this.mid, did);
        })
        .then(() => {
          this.handleQuery();
          this.msgSuccess("删除成功");
        })
        .catch(function () {});
    },

    normalizerDeptTreeNode(e) {
      //      e.id = e.id
      e.label = e.name;
      return e;
    },
  },
};
</script>
<style rel="stylesheet/scss" scoped>
.eqqrcode {
  display: block;
  margin: auto;
}
.form-data {
  margin: 0 -2rem;
}
.btn-orange {
  background-color: #ff9648;
  color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #2196f3;
  color: #fff;
}
.query-form-data ::v-deep .el-col {
  width: 300px;
  margin-right: 15px;
}
.query-form-data ::v-deep .el-form-item__content {
  min-width: 215px;
}

::v-deep .scrolldialog {
  max-height: 82vh;
}

::v-deep .el-dialog__body {
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.head-container {
  font-size: 14px;
}
</style>
