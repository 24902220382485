import request from "@/utils/request";

export function listDormBedHistory(query) {
  return request({
    url: "/dorm/dormbedhistory/list",
    method: "get",
    params: query,
  });
}

export function getDormBedHistory(id) {
  return request({
    url: "/dorm/dormbedhistory/get?id=" + id,
    method: "get",
  });
}

export function addDormBedHistory(data) {
  return request({
    url: "/dorm/dormbedhistory/add",
    method: "post",
    data: data,
  });
}

export function editDormBedHistory(data) {
  return request({
    url: "/dorm/dormbedhistory/edit",
    method: "post",
    data: data,
  });
}

export function delDormBedHistory(ids) {
  return request({
    url: "/dorm/dormbedhistory/del",
    method: "post",
    data: {
      ids: ids,
    },
  });
}

// 门禁
//
export function getaccessControl(data) {
  return request({
    url: "/form/data/list/57235720359941",
    method: "post",
    data,
  });
}
// 查看门禁
//http://localhost:9090/prod-api/form/data/get/57235720359941?id=89
export function lookaccessControl(id) {
  return request({
    url: "/form/data/get/57235720359941?id=" + id,
    method: "get",
  });
}

// 请假管理
export function getleave(data) {
  return request({
    url: "/form/data/list/56392148113413",
    method: "post",
    data,
  });
}
// 查看详情
export function lookleave(id) {
  return request({
    url: "/form/data/get/56392148113413?id=" + id,
    method: "get",
  });
}

// new 请假管理
export function leaveManage(data) {
  return request({
    url: "/bpm/tabledata/list/144",
    method: "post",
    data,
  });
}
//  销假
export function saleManage(data) {
  return request({
    url: "/bpm/tabledata/list/153",
    method: "post",
    data,
  });
}
