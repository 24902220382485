// http://localhost:9090/prod-api/stu/stumajor/list?pageNum=1&pageSize=20
import request from "@/utils/request";

export function getProcess(query) {
  return request({
    url: "/datasource/model/exec",
    method: "post",
    data: query,
  });
}

