<template>
  <div class="app-container">
    <jf-table
      :loading="loading"
      :total="total"
      pageNumField="page"
      pageSizeField="limit"
      :columns="tableColumn"
      :data="dataList"
      :toolbar-config="tableToolbar"
      :query-params="queryParams"
      @checkbox-change="checkboxChangeEvent"
      @page-change="handlePageChange"
      @handleQuery="handleQuery"
      :stripe="true"
      :filterBtn="false"
      :columnsBtn="false"
      :sortBtn="false"
    >
      <!-- <template slot="query_qarams_item">
          <el-form-item label="院系" prop="dept_name">
            <dept-select
              v-model="queryParams.dept_name"
              :defaultLabel="queryParams.dept_name"
              placeholder="请选择院系"
              valueField="no"
              @change="change"
            />
          </el-form-item>
          <el-form-item label="专业" prop="major_name">
            <major-select
              v-model="queryParams.major_name"
              :defaultLabel="queryParams.major_name"
              placeholder="请选择专业"
              :dept_no="queryParams.dept_name"
              valueField="no"
            />
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <dict-select
              v-model="queryParams.gender"
              placeholder="请选择性别"
              type="stu_gender"
              size="small"
            />
          </el-form-item>
          <el-form-item label="学号" prop="no">
            <el-input
              v-model="queryParams.no"
              placeholder="请输入学号"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="queryParams.name"
              placeholder="请输入姓名"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="床位号" prop="source">
            <el-input
              v-model="queryParams.source"
              placeholder="请输入床位号"
              clearable
              size="small"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="房间" prop="source">
            <el-input
              v-model="queryParams.bed_number"
              placeholder="请选择房间"
              size="small"
              style="height: 36px"
              :disabled="true"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="dialogVisible = true"
              >
              </el-button>
            </el-input>
          </el-form-item>
        </template> -->
      <!-- slot="toolbar_btn_left" -->
      <!-- <template slot="toolbar_btn_left">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['/dorm/dormallocbatch/del']"
          >删除</el-button
        >
      </template> -->

      <template slot="toolbar_btn_row" slot-scope="{ row }">
        <el-button
          size="mini"
          type="text"
          icon="el-icon-view"
          @click.stop="handleView(row)"
          >查看
        </el-button>
      </template>
    </jf-table>

    <!-- 获取房间 -->
    <!-- <el-dialog
        title="获取房间"
        :visible.sync="dialogVisible"
        width="80%"
        height="40%"
        :before-close="handleClose"
      >
        <VueBed @changeBea="onChangeBea"></VueBed>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="verify">确 定</el-button>
        </span>
      </el-dialog> -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col>
            <el-form-item label="学号" prop="userno">
              <el-input v-model="form.no" placeholder="请输入学号" disabled />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="姓名">
              <el-input v-model="form.name" placeholder="请输入姓名" disabled />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="业务名称" prop="type">
              <el-input
                v-model="form.qstatus"
                placeholder="请输入业务名称"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="开始时间" prop="type">
              <el-input
                v-model="form.qjkssj"
                placeholder="请输入开始时间"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="结束时间" prop="type">
              <el-input
                v-model="form.qjjssj"
                placeholder="请输入结束时间"
                disabled
              />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="创建时间" prop="type">
              <el-input
                v-model="form.create_time"
                placeholder="请输入创建时间"
                disabled
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #f="{ row }">
        <el-tag
          v-for="dict in statusOptions"
          :key="dict.key"
          :type="dict.type"
          v-if="dict.key == row.status"
        >
          {{ dict.value }}
        </el-tag>
      </template>

      <div slot="footer" class="dialog-footer">
        <template v-if="!readonly">
          <!-- <el-button type="primary" @click="submitForm">确 定</el-button> -->
          <el-button @click="cancel">取 消</el-button>
        </template>
        <el-button v-else @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Qs from "qs";
import { getToken } from "@/utils/auth";
import {
  listDormPreallocStudent,
  getDormPreallocStudent,
  addDormPreallocStudent,
  editDormPreallocStudent,
  delDormPreallocStudent,
} from "@/api/dorm/dorm_prealloc_student";

// import VueBed from "./bed.vue";
import { listDormBed } from "@/api/dorm/dorm_bed";
import {
  listDormBedHistory,
  getDormBedHistory,
  addDormBedHistory,
  editDormBedHistory,
  delDormBedHistory,
  getaccessControl,
  lookaccessControl,
  getleave,
  lookleave,
  leaveManage,
  saleManage,
} from "@/api/dorm/dorm_bed_history";
import { getDicts } from "@/api/core/data/dict/data";
export default {
  name: "dorm-prealloc-student",
  //   components: {
  //     VueBed,
  //   },
  props: {
    prealloc_id: {
      type: Number,
      default: 0,
    },
    no: {
      type: String,
      default: "",
    },
    // types: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  data() {
    return {
      dialogVisible: false,
      beaValue: {},

      // 状态数据字典
      statusOptions: [
        { key: "-1", value: "挂起", type: "danger" },
        { key: "0", value: "草稿", type: "" },
        { key: "1", value: "待审批", type: "warning" },
        { key: "2", value: "审批通过", type: "success" },
        { key: "3", value: "撤回", type: "danger" },
        { key: "4", value: "驳回", type: "danger" },
        { key: "5", value: "终止", type: "danger" },
        { key: "6", value: "作废", type: "danger" },
      ],

      tableToolbar: {
        // 表格是否包裹刷新,全屏,列设置
        perfect: false,
        // 全屏
        zoom: false,
        // 列设置
        custom: false,
        // 刷新
        // refresh: {
        //   query: this.handleQuery,
        // },
        // 插槽名称
        slots: {
          buttons: "toolbar_buttons",
        },
      },
      tableColumn: [
        { type: "checkbox", width: 60, fixed: "left", visible: false },
        { type: "seq", width: 60, title: "序号" },
        {
          field: "process_status",
          title: "审批状态",
          minWidth: 120,
          // slots: {
          //   default: (row, h) => {
          //     return <span v-text="112233">12313</span>;
          //   },
          // },

          formatter: (e) => {
            let statusOptions = [
              { key: "-1", value: "挂起", type: "danger" },
              { key: "0", value: "草稿", type: "" },
              { key: "1", value: "待审批", type: "warning" },
              { key: "2", value: "审批通过", type: "success" },
              { key: "3", value: "撤回", type: "danger" },
              { key: "4", value: "驳回", type: "danger" },
              { key: "5", value: "终止", type: "danger" },
              { key: "6", value: "作废", type: "danger" },
            ];
            let str = "";
            statusOptions.map((item) => {
              if (item.key == e.cellValue) {
                str = item.value;
                // str = <el-tag type="item.type">{{item.value}}</el-tag>;
              }
            });
            return str;
          },
        },
        {
          field: "starter_account",
          title: "学号",
          minWidth: 120,
        },
        { field: "starter_name", title: "姓名", minWidth: 90 },

        {
          field: "date8",
          title: "回校时间",
          minWidth: 120,
        },
        {
          field: "form41_daterange32",
          title: "请假时间",
          minWidth: 120,
        },
        {
          field: "form41_textarea8",
          title: "请假事由",
          minWidth: 120,
        },
        {
          field: "dept_name",
          title: "所属院系",
          minWidth: 80,
        },
        {
          field: "form61_classes_no",
          title: "所属专业",
          minWidth: 80,
          formatter: (e) => {
            return e.cellValue.substr(3);
          },
        },
        {
          field: "form61_classes_no",
          title: "所属班级",
          minWidth: 120,
        },
        {
          field: "form41_calculate14",
          title: "请假天数",
          minWidth: 80,
        },

        {
          field: "",
          title: "操作",
          width: 80,
          fixed: "right",
          align: "center",
          slots: { default: "defaultopr" },
          visible: true,
        },
      ],
      loading: true,
      ids: [],
      single: true,
      multiple: true,
      total: 0,
      total2: 0,
      dataList: [],
      dataList2: [],
      female: 0,
      female2: 0,
      title: "",
      readonly: false,
      open: false,
      queryParams: {
        page: 1,
        limit: 10,
        alloc_batch_id: undefined,
        prealloc_id: undefined,
        student_id: undefined,
        no: undefined, // 学号
        name: undefined, // 姓名
        dept_no: undefined,
        dept_name: undefined, // 院系
        major_no: undefined,
        major_name: undefined, // 专业
        class_no: undefined,
        class_name: undefined,
        grade: undefined,
        gender: undefined, // 性别
        ethnicity: undefined,
        source: undefined, // 床位号
        bed_number: undefined, // 房间
        bed_id: undefined, // 房间id
      },
      form: {},
      // formExt: {},
      // extFieldPrefix: "_ext_",
      // upload: {
      //   open: false,
      //   title: "",
      //   isUploading: false,
      //   update: false,
      //   headers: { Authorization: getToken() },
      //   url: process.env.VUE_APP_BASE_API + "/dorm/dormpreallocstudent/import",
      // },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
      },
      // 选中值
      records: [],
    };
  },
  created() {
    this.queryParams["userno"] = this.no;
    // this.queryParams["userno"] = "202313007027";
    // this.queryParams["userno"] = "";

    this.getList();
  },
  methods: {
    getList() {
      this.loading = false;
      // let str = {
      //   no: {
      //     like: this.queryParams.userno,
      //   },
      //   id: {
      //     orderby: "desc",
      //   },
      // };
      // let data = {
      //   mid: "56392148113413",
      //   page: this.queryParams.page,
      //   psize: this.queryParams.limit,
      //   qstr: JSON.stringify(str),
      // };

      // getleave(data).then((response) => {
      //   this.dataList = response.data.list;
      //   this.total = response.data.total;
      //   this.loading = false;
      // });
      let str = {
        id: {
          orderby: "desc",
        },
        form61_no: {
          like: this.queryParams.userno,
        },
      };

      let data = {
        flowid: "153",
        page: 1,
        psize: 10,
        qstr: JSON.stringify(str),
      };
      saleManage(data)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            let response = JSON.parse(res.data.rows);
            response.rows.map((item) => {
              item.vars = JSON.parse(item.vars);
              // item.daterange32 = JSON.parse(item.daterange32);
              item.fields = JSON.parse(item.fields);
              item.form61 = JSON.parse(item.form61);
            });
            this.dataList = response.rows;
            this.total = response.total;
            this.loading = false;
            console.log("销假资料 ==> ", this.dataList);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 学院数据
    change(a, b, c) {
      this.queryParams.dept_no = c;
    },
    // 选中房间
    onChangeBea(item) {
      this.beaValue = item;
      this.queryParams.bed_id = item.id;
    },
    // 关闭
    handleClose(done) {
      done();
    },

    // 数据
    dictFormat(e) {
      const col = this.tableColumn[e.columnIndex];
      if (col.dictData) {
        const values = e.cellValue ? e.cellValue.split(",") : [];
        const labels = [];
        for (let i = 0; i < values.length; i++) {
          const v = values[i];
          for (let j = 0; j < col.dictData.values.length; j++) {
            const item = col.dictData.values[j];
            if (v == item.key) {
              labels.push(item.value);
              break;
            }
          }
        }
        return labels.join(",");
      }
      return e.cellValue;
    },

    // 重置
    cancel() {
      this.open = false;
      this.reset();
    },

    // 重置
    reset() {
      this.form = {
        page: undefined,
        limit: undefined,
        no: undefined, // 学号
        name: undefined, // 姓名
        dept_no: undefined,
        dept_name: undefined, // 院系
        major_no: undefined,
        major_name: undefined, // 专业
        class_no: undefined,
        class_name: undefined,
        gender: undefined, // 性别
        source: undefined, // 床位号
        bed_number: undefined, // 房间
        room_id: undefined, // 房间id
      };
      this.resetForm("form");
      this.queryParams = this.form;
      this.queryParams2 = this.form;
    },
    // 搜索按钮
    handleQuery(obj) {
      if (obj && obj.qc_resultData) {
        this.reset();
      }

      this.queryParams.page = 1;
      this.getList();
      this.queryParams2.page = 1;
      this.getNo();
    },

    // resetQuery() {
    //   this.resetForm("queryForm");
    //   this.handleQuery();
    // },

    // 查看
    // handleView(row) {
    //   const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
    //   const loading = this.$loading({
    //     text: "正在加载...",
    //   });
    //   getDormPreallocStudent(id)
    //     .then((response) => {
    //       loading.close();
    //       this.form = response.data;
    //       this.title = "查看";
    //       this.readonly = true;
    //       this.open = true;
    //     })
    //     .catch((e) => {
    //       loading.close();
    //     });
    // },
    checkboxChangeEvent({ records }) {
      // console.log("records", records);
      this.records = records;
      this.ids = records.map((item) => item.id);
      this.single = records.length != 1;
      this.multiple = !records.length;
    },
    // 已分配选中
    // radioChangeEvent({ row }) {
    //   console.log("选中的数据 =>", row);
    // },
    // // 未分配选中
    // radioChangeEvent({ row }) {
    //   console.log("选中的数据 =>", row);
    // },

    handlePageChange({ currentPage, pageSize }) {
      if (this.queryParams.limit == pageSize) {
        this.getList();
      } else {
        this.queryParams.limit = pageSize;
        this.handleQuery();
      }
    },
    handlePageChange2({ currentPage, pageSize }) {
      if (this.queryParams.limit == pageSize) {
        this.getNo();
      } else {
        this.queryParams2.limit = pageSize;
        this.handleQuery();
      }
    },

    handleDelete(row) {
      if (row.id) {
        this.ids = [row.id];
      }
      this.$confirm("确定删除选中的数据吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.delDormAllocBatch();
        })
        .then(() => {
          setTimeout(() => {
            this.getList();
          }, 300);
          this.msgSuccess("删除成功");
        })
        .catch(() => {});
    },

    // 删除选中
    delDormAllocBatch() {
      new Promise((resolve) => {
        this.$emit("delReviewArr", this.ids);
        resolve();
      });
    },
    convertTimestampToDateTime(timestamp) {
      // 创建一个新的 Date 对象
      var date = new Date(timestamp * 1000); // 时间戳通常是秒级的，所以需要乘以 1000 转换为毫秒

      // 获取日期各部分
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() 是从 0 开始的，所以要加 1
      var day = ("0" + date.getDate()).slice(-2);
      var hour = ("0" + date.getHours()).slice(-2);
      var minute = ("0" + date.getMinutes()).slice(-2);
      var second = ("0" + date.getSeconds()).slice(-2);

      // 构建日期时间字符串
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },

    // 查看
    handleView(row) {
      console.log("row", row);
      // /bpm/task/view?resourceId=1543&id=355&title=销假申请
      this.$router.push({
        path: "/bpm/task/view",
        query: {
          resourceId: row.resource_id,
          id: row.id,
          title: "销假申请",
        },
      });

      return;

      const id = row.id || (this.ids.length > 0 ? this.ids[0] : "");
      const loading = this.$loading({
        text: "正在加载...",
      });

      let arr = [
        { name: "1", value: "未请假" },
        { name: "2", value: "申请待处理" },
        { name: "3", value: "请假中" },
        { name: "4", value: "销假逾期" },
        { name: "5", value: "销假确认中" },
      ];

      lookleave(id)
        .then((response) => {
          loading.close();
          this.form = response.data.row;

          arr.map((item) => {
            if (item.name == this.form.qstatus) {
              this.form.qstatus = item.value;
            }
          });

          this.form.create_time = this.convertTimestampToDateTime(
            this.form.create_time
          );

          this.title = "查看";
          this.readonly = true;
          this.open = true;
        })
        .catch((e) => {
          loading.close();
          console.log("e", e);
        });
    },
  },
};
</script>

<style lang="scss">
.el-input-group__append {
  border-left: 0;
  background: #409eff;
  color: #fff;
  height: 36px;
}
.el-input--small .el-input__inner {
  height: 36px;
  line-height: 36px;
}
.el-input.is-disabled .el-input__inner {
  background-color: #fff;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
// .vxe-table--render-default .vxe-body--row.row--stripe {
//   background-color: #e3f1ff;
// }
.vxe-table--header {
  background-color: #e3f1ff;
}
</style>
